import React, {FormEvent, useEffect, useState} from 'react'
import {SlipDetailResponse} from '../core/_models'
import dayjs from 'dayjs'

const TicketForm = ({ticketInfo}: {ticketInfo?: SlipDetailResponse}) => {
  const [createdAt, setCreatedAt] = useState('')
  const [redeemedOrCancelledBy, setRedeemedOrCancelledBy] = useState('')
  const [wonAmount, setWonAmount] = useState('')
  const [retailer, setRetailer] = useState('')
  const [placedBy, setPlacedBy] = useState('')
  const [redeemedBy, setRedeemedBy] = useState('')

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    // Reset the form fields after submission if needed
    setCreatedAt('')
    setRedeemedOrCancelledBy('')
    setWonAmount('')
    setRetailer('')
    setPlacedBy('')
    setRedeemedBy('')
  }

  useEffect(() => {
    if (ticketInfo && ticketInfo.ticket.length > 0) {
      setCreatedAt(ticketInfo.ticket?.[0]?.created_at)
      setRedeemedOrCancelledBy(ticketInfo.ticket?.[0]?.redeemed_or_cancelled_at ?? '')
      setWonAmount(ticketInfo.ticket?.[0]?.won_amount?.toString())
      setRetailer(ticketInfo.ticket?.[0]?.retailer)
      setPlacedBy(ticketInfo.ticket?.[0]?.placed_by)
      setRedeemedBy(ticketInfo.ticket?.[0]?.admin ?? ticketInfo.ticket?.[0]?.redeemed_by)
    } else {
      setCreatedAt('')
      setRedeemedOrCancelledBy('')
      setWonAmount('')
      setRetailer('')
      setPlacedBy('')
      setRedeemedBy('')
    }
  }, [ticketInfo])

  return (
    <form onSubmit={handleSubmit} className='col-4 px-3 py-4 '>
      <h6>Ticket Information</h6>
      <hr></hr>
      <div className='mb-3'>
        <label className='form-label'>Created At:</label>
        <input
          type='text'
          className='form-control'
          value={createdAt ? dayjs(createdAt).format('DD-MM-YYYY hh:mm A') : ''}
          onChange={(e) => setCreatedAt(e.target.value)}
          disabled
        />
      </div>
      <div className='mb-3'>
        <label className='form-label'>Won Amount:</label>
        <input
          type='text'
          className='form-control'
          value={wonAmount ?? ''}
          onChange={(e) => setWonAmount(e.target.value)}
          disabled
        />
      </div>
      <div className='mb-3'>
        <label className='form-label'>Retailer:</label>
        <input
          type='text'
          className='form-control'
          value={retailer ?? ''}
          onChange={(e) => setRetailer(e.target.value)}
          disabled
        />
      </div>
      <div className='mb-3'>
        <label className='form-label'>Placed By:</label>
        <input
          type='text'
          className='form-control'
          value={placedBy ?? ''}
          onChange={(e) => setPlacedBy(e.target.value)}
          disabled
        />
      </div>
      <div className='mb-3'>
        <label className='form-label'>Redeemed/Canceled By:</label>
        <input
          type='text'
          className='form-control'
          value={redeemedBy ?? ''}
          onChange={(e) => setRedeemedBy(e.target.value)}
          disabled
        />
      </div>
      <div className='mb-3'>
        <label className='form-label'>Redeemed/Cancelled At:</label>
        <input
          type='text'
          className='form-control'
          // value={redeemedOrCancelledBy}
          value={
            redeemedOrCancelledBy ? dayjs(redeemedOrCancelledBy).format('DD-MM-YYYY hh:mm A') : ''
          }
          onChange={(e) => setRedeemedOrCancelledBy(e.target.value)}
          disabled
        />
      </div>
    </form>
  )
}

export default TicketForm
