import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../_metronic/helpers'
import {DisableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import {fetchDashboardData} from './core/_requests'
import {RangeType} from './types/dashboard.types'

import {Days} from './components/days'
import {AdminCard, SuperAdminCard, AdminTable, SuperAdminTable} from './components/index'
import {DashboardLoading} from './components/DashboardLoading'

const DashboardPage = ({range, userType}: {range: RangeType; userType: string | undefined}) => {
  const {isFetching, data} = useQuery(
    `${QUERIES.DASHBOARD}-${range}`,
    () => {
      return fetchDashboardData(range)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  if (isFetching) {
    return <DashboardLoading></DashboardLoading>
  }

  let titles: string[] = [
    'Pay-In',
    'Pay-Out',
    'Unclaimed',
    'Net',
    'Created Shops',
    'RTP/MAR Keno',
    'RTP/MAR Spin And Win',
    'RTP/MAR Bingo',
    'RTP/MAR Runner',
  ]
  let chartImgs: string[] = [
    'finance/fin002.svg',
    'finance/fin010.svg',
    'general/gen012.svg',
    'finance/fin008.svg',
    'ecommerce/ecm004.svg',
    'games/keno.svg',
    'games/spin.svg',
    'games/bingo.svg',
    'games/runner.svg',
  ]

  let cards =
    data &&
    titles.map((title, i) => (
      <AdminCard data={data} title={title} imgSrc={chartImgs[i]} key={i} range={range} />
    ))

  let table = data && <AdminTable data={data} />

  if (userType && (userType === 'SuperAdmin' || userType === 'Finance')) {
    titles = ['Total Sales', 'Net Revenue', 'Tenants', 'Agents', 'Created Shops', 'Service Fee']
    chartImgs = [
      'finance/fin002.svg',
      'finance/fin008.svg',
      'communication/com005.svg',
      'general/gen049.svg',
      'ecommerce/ecm004.svg',
      'finance/fin010.svg',
    ]
    cards =
      data &&
      titles.map((title, i) => (
        <SuperAdminCard data={data} title={title} imgSrc={chartImgs[i]} key={i} />
      ))

    table = data && <SuperAdminTable data={data} />
  }

  return (
    <>
      <div className='row gy-3 g-xl-10 pt-0'>
        {cards?.map((card) => card)}
        <h1>
          Highest Performing
          {userType && (userType === 'SuperAdmin' || userType === 'Finance')
            ? ' Organizations'
            : ' Retails'}
        </h1>
        <div className='row gy-3'>
          <div className='col-sm-12'>{table}</div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const [range, setRange] = useState<RangeType>('TODAY')

  const handleCardToolbarClick = (event: React.MouseEvent<HTMLDivElement>, value: RangeType) => {
    event.preventDefault()
    setRange(value)
  }
  const {currentUser} = useAuth()

  return (
    <DisableSidebar>
      <PageTitle description='' breadcrumbs={[]}>
        {`Hello, ${currentUser?.name}`}
      </PageTitle>

      <Days activeDay={range} clicked={handleCardToolbarClick} range={range} />
      <DashboardPage range={range} userType={currentUser?.userType} />
    </DisableSidebar>
  )
}

export {DashboardWrapper}
