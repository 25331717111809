import axios, {AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {ID, QueryState, Response, SearchResponse} from '../../../../_metronic/helpers'
import {
  CompanyToGameModel,
  DashboardModel,
  GameAssosiateModel,
  Games,
  Organization,
  OrganizationsQueryResponse,
  SearchQueryResponse,
  GamesQueryResponse,
  AssignedGames,
} from './_models'

const API_URL = import.meta.env.REACT_APP_API_URL
const ORGANIZATIONS_URL = `${API_URL}/companies`
// const SYSTEM_SETTINGS_URL = `${API_URL}/system-settings`
const SEARCH_URL = `${API_URL}/search`
const model = 'Organization'
const gameModel = 'Game'

// Temp URL
// const GAME_URL_TEMP = `http://192.168.100.48:3010/games`

// Games
const GAME_URL = `${API_URL}/games`
const ASSOCIATE_GAMES_URL = `${API_URL}/games/associate`
const DISASSOCIATE_GAME_URL = `${API_URL}/games/disassociate`

const DASHBOARD_URL = `${API_URL}/dashboards/company`
const SYSTEM_SETTINGS_URL = `${API_URL}/system-settings/maintenance-mode`
const SUPER_SYSTEM_SETTINGS_URL = `${API_URL}/system-settings/super-maintenance-mode`
const GAME_MAINTENANCE_MODE_URL = `${API_URL}/games/maintenance-mode`

const searchOrganizations = (query: Partial<QueryState>): Promise<SearchQueryResponse> => {
  return axios
    .post(`${SEARCH_URL}`, {indexName: 'companies', ...query})
    .then((d: AxiosResponse<SearchResponse<Array<Organization>>>) => {
      return d.data
    })
}

const getOrganizations = (query: string): Promise<OrganizationsQueryResponse> => {
  return axios
    .get(`${ORGANIZATIONS_URL}?${query}`)
    .then((d: AxiosResponse<Response<Array<Organization>>>) => {
      console.log(d.data)
      return d.data
    })
}

const getOrganizationById = (id: ID) => {
  return axios.get(`${ORGANIZATIONS_URL}/${id}`).then((d) => d.data)
}

const createOrganization = (org: Organization): Promise<Organization | undefined> => {
  let fd = new FormData()
  fd.append('name', org.name ?? '')
  fd.append('admin_name', org.admin_name ?? '')
  fd.append('username', org.username ?? '')
  fd.append('phone_number', org.phone_number ?? '')
  fd.append('email', org.email ?? '')
  if (org.password?.trim() !== '') {
    fd.append('password', org.password ?? '')
  }
  fd.append('admin_id', `${org.admin_id}`)
  fd.append('logolight', org.logo_light ?? '')
  fd.append('logodark', org.logo_dark ?? '')
  // fd.append('rtp_min', `${org.rtp_min}`)
  // fd.append('rtp_max', `${org.rtp_max}`)
  // fd.append('daily_id_start', `${org.daily_id_start}`)
  if (org.maintenance_mode !== undefined) {
    fd.append('maintenance_mode', `${org.maintenance_mode}`)
  }
  if (org.status !== undefined) {
    fd.append('status', `${org.status}`)
  }
  return axios
    .post(ORGANIZATIONS_URL, fd)
    .then((response: AxiosResponse<Response<Organization>>) => {
      if (String(response.status).startsWith('2')) toast.success(`${model} created successfully`)
      else toast.error(`Create ${model} failed`)
      return response.data
    })
    .then((response: Response<Organization>) => response.data)
    .catch((error) => {
      toast.error(
        error.response.data['errors']
          ? error.response.data['errors'][0]
          : error.response.data.message ?? `Something went wrong`
      )
      throw error
      // return undefined
    })
}

export const toggleOrgActivation = (status: string, id: ID): Promise<Organization | undefined> => {
  return axios
    .patch(`${ORGANIZATIONS_URL}/${id}`, {status})
    .then((response: AxiosResponse<Response<Organization>>) => {
      if (String(response.status).startsWith('2')) toast.success(`${model} updated successfully`)
      else toast.error(`Update ${model} failed`)
      return response.data
    })
    .then((response: Response<Organization>) => response.data)
    .catch((error) => {
      toast.error(
        error.response.data['errors']
          ? error.response.data['errors'][0]
          : error.response.data.message ?? `Something went wrong`
      )
      throw error
      // return undefined
    })
}

const updateOrganization = (org: Organization): Promise<Organization | undefined> => {
  let fd = new FormData()
  fd.append('name', org.name ?? '')
  fd.append('admin_name', org.admin_name ?? '')
  fd.append('username', org.username ?? '')
  fd.append('phone_number', org.phone_number ?? '')
  fd.append('email', org.email ?? '')
  if (org.password?.trim() !== '') {
    fd.append('password', org.password ?? '')
  }
  fd.append('admin_id', `${org.admin_id}`)
  fd.append('logolight', org.logo_light ?? '')
  fd.append('logodark', org.logo_dark ?? '')
  // fd.append('rtp_min', `${org.rtp_min}`)
  // fd.append('rtp_max', `${org.rtp_max}`)
  // fd.append('daily_id_start', `${org.daily_id_start}`)
  if (org.maintenance_mode !== undefined) {
    fd.append('maintenance_mode', `${org.maintenance_mode}`)
  }
  fd.append('status', `${org.status}`)
  return axios
    .patch(`${ORGANIZATIONS_URL}/${org.id}`, fd)
    .then((response: AxiosResponse<Response<Organization>>) => {
      if (String(response.status).startsWith('2')) toast.success(`${model} updated successfully`)
      else toast.error(`Update ${model} failed`)
      return response.data
    })
    .then((response: Response<Organization>) => response.data)
    .catch((error) => {
      toast.error(
        error.response.data['errors']
          ? error.response.data['errors'][0]
          : error.response.data.message ?? `Something went wrong`
      )
      throw error
      // return undefined
    })
}

const deleteOrganization = (organizationId: ID): Promise<void> => {
  return axios
    .delete(`${ORGANIZATIONS_URL}/${organizationId}`)
    .then((res) => {
      if (String(res.status).startsWith('2')) toast.success(`${model} deleted successfully`)
    })
    .catch((error) => {
      toast.error(
        error.response.data['errors']
          ? error.response.data['errors'][0]
          : error.response.data.message ?? `Something went wrong`
      )
      throw error
    })
}

const deleteSelectedOrganizations = (organizationIds: Array<ID>): Promise<void> => {
  const requests = organizationIds.map((id) => axios.delete(`${ORGANIZATIONS_URL}/${id}`))
  return axios
    .all(requests)
    .then((res) => {
      if (String(res[0].status).startsWith('2')) toast.success(`${model} deleted successfully`)
    })
    .catch((error) => {
      toast.error(
        error.response.data['errors']
          ? error.response.data['errors'][0]
          : error.response.data.message ?? `Something went wrong`
      )
      throw error
    })
}

export const changeSuperMaintenanceMode = (value: '0' | '1') => {
  return axios
    .put(`${SUPER_SYSTEM_SETTINGS_URL}`, {value})
    .then((response) => {
      if (String(response.status).startsWith('2'))
        toast.success('Maintenance mode changed successfully')
      else toast.error('Something went wrong')
      return response.status
    })
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          `Something went wrong`
      )
      throw error
    })
}

const getGamesByCompany = (company_id: ID): Promise<GamesQueryResponse> => {
  return axios
    .get(`${GAME_URL}/${company_id}`)
    .then((d: AxiosResponse<Response<CompanyToGameModel>>) => d.data)
}

const updateGamesByCompany = (company_id: ID, data: AssignedGames): Promise<void> => {
  const {game_type, maintenance_mode, ...rest} = data
  const formattedData = {
    game_type,
    custom_settings: {
      house_edge_min: rest.house_edge_min?.toString(),
      house_edge_max: rest.house_edge_max?.toString(),
      daily_id_start: rest.daily_id_start?.toString(),
      bingo_max_house_edge: rest.bingo_max_house_edge?.toString(),
      bingo_min_house_edge: rest.bingo_min_house_edge?.toString(),
    },
  }

  return axios
    .patch(`${GAME_URL}/${company_id}`, formattedData)
    .then((res) => {
      if (String(res.status).startsWith('2')) toast.success(`${gameModel} updated successfully`)
    })
    .catch((error) => {
      toast.error(
        error.response.data['errors']
          ? error.response.data['errors'][0]
          : error.response.data.message ?? `Something went wrong`
      )
      throw error
    })
}

const getGames = (): Promise<Games[]> => {
  return axios.get(`${GAME_URL}`).then((d: AxiosResponse<Games[]>) => d.data)
}

const associateGame = (company_id: ID, data: GameAssosiateModel): Promise<void> => {
  return axios
    .post(`${ASSOCIATE_GAMES_URL}/${company_id}`, data)
    .then((res) => {
      if (res) toast.success(`${gameModel} created successfully`)
    })
    .catch((error) => {
      toast.error(
        error.response.data['errors']
          ? error.response.data['errors'][0]
          : error.response.data.message ?? `Something went wrong`
      )
      throw error
    })
}

const disassociateGame = (company_id: ID, game_type: Games): Promise<void> => {
  return axios
    .patch(`${DISASSOCIATE_GAME_URL}/${company_id}`, {
      game_type: game_type,
    })
    .then((res) => {
      if (String(res.status).startsWith('2')) toast.success(`${gameModel} deleted successfully`)
    })
    .catch((error) => {
      toast.error(
        error.response.data['errors']
          ? error.response.data['errors'][0]
          : error.response.data.message ?? `Something went wrong`
      )
      throw error
    })
}

const getDashboardData = (range: string, company_id: ID): Promise<DashboardModel> => {
  return axios
    .get(`${DASHBOARD_URL}/${company_id}?range=${range}`)
    .then((d: AxiosResponse<DashboardModel>) => d.data)
}

const changeOrganizationMaintenanceMode = (company_id: ID, data: {value: '0' | '1'}) => {
  return axios
    .put(`${SYSTEM_SETTINGS_URL}/${company_id}`, data)
    .then((res) => {
      if (String(res.status).startsWith('2'))
        toast.success(`Organization Maintenance mode changed successfully`)
      else toast.error('Something went wrong')
      return res.status
    })
    .catch((error) => {
      toast.error(
        error.res?.data?.['errors']?.[0] ?? error.res.data.message ?? `Something went wrong`
      )
      throw error
    })
}

const changeGameMaintenanceMode = (company_id: ID, value: '0' | '1', game_type: Games) => {
  return axios
    .post(`${GAME_MAINTENANCE_MODE_URL}/${company_id}`, {value, game_type})
    .then((res) => {
      if (String(res.status).startsWith('2'))
        toast.success(`Game Maintenance mode changed successfully`)
      else toast.error('Something went wrong')
      return res.status
    })
    .catch((error) => {
      console.log(error)
      toast.error(
        'something went wrong'
        // error.res?.data?.['errors']?.[0] ?? error.res.data.message ?? `Something went wrong`
      )
      throw error
    })
}

export {
  getOrganizationById,
  getOrganizations,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  deleteSelectedOrganizations,
  searchOrganizations,
  getGamesByCompany,
  getGames,
  getDashboardData,
  disassociateGame,
  associateGame,
  changeOrganizationMaintenanceMode,
  changeGameMaintenanceMode,
  updateGamesByCompany,
}
