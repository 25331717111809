import React, {useEffect, useState} from 'react'
import {Filter, SlipDetailResponse, Ticket} from '../core/_models'
import {fetchTicketInfo} from '../core/_requests'

const ReceiptReportsForm = ({
  setTicketInfo,
}: {
  setTicketInfo: React.Dispatch<React.SetStateAction<SlipDetailResponse | undefined>>
}) => {
  const [date, setDate] = useState<string | number | readonly string[] | undefined>('')
  const [filter, setFilter] = useState<Filter>()
  const [, setTicketData] = useState<Ticket | null>(null)

  const updateFilters = (e: any) => {
    const {name, value} = e.target
    if (value === '') {
      setFilter({...filter, [name]: undefined})
      return
    }
    setFilter({...filter, [name]: value})
  }

  const updateFilterState = async (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault()
    try {
      const response = await fetchTicketInfo(filter?.slip_id)
      if (response) {
        setTicketInfo(response) // Set the response directly
      }
    } catch (error) {
      console.error('Error fetching ticket info:', error)
      setTicketData(null)
    }
  }

  const clearForm = () => {
    setFilter({})
    for (let id of [
      'id',
      'receipt_number',
      'dates',
      'cashier_id',
      'retailer_id',
      'agent_id',
      'slip_id',
    ]) {
      const item = document.getElementById(id) as HTMLFormElement
      if (item) item.value = ''
    }
    setDate(undefined)
    setTicketInfo(undefined)
  }

  const computeDate = (rawDate: number) => {
    const newDate = rawDate.toString()
    if (newDate.length === 1) return '0' + newDate
    return newDate
  }

  let nowDate = null

  useEffect(() => {
    nowDate = new Date()
    const computedDate = [
      nowDate.getFullYear(),
      computeDate(nowDate.getMonth() + 1),
      computeDate(nowDate.getDate()),
    ].join('-')
    setDate(computedDate)
  }, [])

  useEffect(() => {
    setFilter({...filter, date: date})
  }, [date])

  return (
    <>
      <div className='card-body px-4 py-4'>
        <form className='form form-label-right'>
          <div className='form-group row'>
            <div className='col-4 flex '>
              <div className='flex flex-column '>
                <label>
                  Slip Number: <span className='text-danger'>*</span>
                </label>
                <input
                  type='number'
                  step={1}
                  className='form-control my-2'
                  id='slip_id'
                  name='slip_id'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      updateFilterState(e)
                    }
                  }}
                  onInput={updateFilters}
                />
              </div>
            </div>
            <div className='col-6 my-5'>
              <button
                type='reset'
                className='btn btn-sm m-3'
                onClick={clearForm}
                style={{
                  background: '#F1FAFF',
                  color: '#3699FF',
                }}
              >
                Clear
              </button>
              <button
                type='button'
                className='btn btn-sm btn-primary m-2'
                onClick={updateFilterState}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        <div className='row'>
          <div className='col-6 d-flex flex-col justify-content-end align-items-center'></div>
        </div>
      </div>
    </>
  )
}

export {ReceiptReportsForm}
