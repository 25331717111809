import React, {useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {ListViewProvider} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import TicketInformation from './TicketInformation'
import {BetSlip} from '../table/BetSlip'
import {SlipDetailResponse} from '../core/_models'

type TabKey = 'slip_detail' | 'slip_report'

function TicketWrapper({ticketInfo}: {ticketInfo?: SlipDetailResponse}) {
  const [activeTab] = useState<TabKey>('slip_detail')

  return (
    <>
      <PageTitle>{activeTab === 'slip_detail' ? 'Slip Detail' : 'Slip Report'}</PageTitle>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <div className=' d-flex  flex-row-fluid  '>
              <TicketInformation ticketInfo={ticketInfo} />
              <div className='d-flex flex-column flex-row-fluid'>
                <BetSlip ticketInfo={ticketInfo} />
              </div>
            </div>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {TicketWrapper}
