import {ReactNode} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

type CardProps = {
  title: string
  payment: number
  per_ticket: number
  imgSrc: string
}

function Card(props: CardProps) {
  const {payment, per_ticket, imgSrc, title} = props

  return (
    <div className='col-lg-4 col-md-6 col-sm-12'>
      <div className='card p-8'>
        <div className='card flex flex-row justify-content-between'>
          <div>
            <div className='h3 pb-8'>{title}</div>
            <div className='h1'>{payment} ETB</div>
            <div className='text-muted h6 pt-2 fw-normal fs-4'>{per_ticket} Tickets</div>
          </div>
          <div>
            <img
              src={toAbsoluteUrl(`/media/svg/data/${imgSrc}`)}
              alt='data-icon'
              className='h-60'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
