/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()

  const auth = useAuth()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='abstract-28'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {auth?.currentUser?.userType === 'SuperAdmin' || auth?.currentUser?.userType === 'Finance' ? (
        <AsideMenuItem
          to='/organization'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.ORGANIZATIONS'})}
          fontIcon='bi-app-indicator'
        />
      ) : null}
      {auth?.currentUser?.userType === 'SuperAdmin' || auth?.currentUser?.userType === 'Finance' ? (
        <AsideMenuItem
          to='/tracking-bills'
          icon='abstract-28'
          title='Tracking Bills'
          fontIcon='bi-app-indicator'
        />
      ) : null}
      {auth?.currentUser?.userType !== 'SuperAdmin' && auth?.currentUser?.userType !== 'Finance' ? (
        <AsideMenuItemWithSub
          to='/apps/user-management'
          title='User Management'
          fontIcon='bi-chat-left'
          icon='black-right'
        >
          {auth?.currentUser?.userType === 'Admin' ? (
            <>
              <AsideMenuItem to='/apps/user-management/admins' title='Admins' hasBullet={true} />
              <AsideMenuItem to='/apps/user-management/agents' title='Agents' hasBullet={true} />
            </>
          ) : null}
          <AsideMenuItem to='/apps/user-management/cashiers' title='Cashiers' hasBullet={true} />
          {auth?.currentUser?.userType === 'Admin' ? (
            <AsideMenuItem to='/apps/user-management/roles' title='Roles' hasBullet={true} />
          ) : null}
        </AsideMenuItemWithSub>
      ) : null}
      {auth?.currentUser?.userType !== 'SuperAdmin' && auth?.currentUser?.userType !== 'Finance' ? (
        <>
          {' '}
          <AsideMenuItem
            to='/apps/retailers/index'
            title='Retailers'
            fontIcon='bi-chat-left'
            icon='black-right'
          />
          <AsideMenuItem
            to='/apps/balance-adjustment/index'
            title='Balance Adjustment'
            fontIcon='bi-chat-left'
            icon='black-right'
          />
        </>
      ) : null}
      {auth?.currentUser?.userType === 'Admin' ? (
        <AsideMenuItem
          to='/slip-management'
          icon='abstract-28'
          title='Slip Management'
          fontIcon='bi-layers'
        />
      ) : null}
      {auth?.currentUser?.userType === 'Admin' ? (
        <AsideMenuItem
          to='/jackpot/index'
          icon='abstract-28'
          title='Jackpot'
          fontIcon='bi-layers'
        />
      ) : null}
      {auth?.currentUser?.userType !== 'SuperAdmin' && auth?.currentUser?.userType !== 'Finance' ? (
        <AsideMenuItem to='/reports' icon='black-right' title='Reports' fontIcon='bi-layers' />
      ) : null}
      {auth?.currentUser?.userType === 'Admin' || auth.currentUser?.userType === 'Agent' ? (
        <AsideMenuItem
          to='/apps/settings/index'
          title='System Settings'
          fontIcon='bi-chat-left'
          icon='black-right'
        />
      ) : null}

      {auth?.currentUser?.userType === 'SuperAdmin' || auth?.currentUser?.userType === 'Finance' ? (
        <AsideMenuItem
          to='/finance'
          icon='abstract-28'
          title={intl.formatMessage({id: 'MENU.FINANCE USER'})}
          fontIcon='bi-app-indicator'
        />
      ) : null}

      {auth?.currentUser?.userType === 'SuperAdmin' || auth?.currentUser?.userType === 'Finance' ? (
        <AsideMenuItem to='/odds/index' icon='abstract-28' title='Odds' fontIcon='bi-layers' />
      ) : null}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
