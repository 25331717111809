import {useQueryStakeInfo} from '../core/QueryResponseProvider'

export const cards = [
  {
    name: 'Gross Stake',
    key: 'gross_stake',
    imgClass: 'fa fa-sliders',
  },
  {
    name: 'Tickets',
    key: 'tickets',
    imgClass: 'fa fa-credit-card',
  },
  {
    name: 'Max Gross Stake',
    key: 'max_gross_stake',
    imgClass: 'fa fa-sliders',
  },
  {
    name: 'Min Gross Stake',
    key: 'min_gross_stake',
    imgClass: 'fa fa-sliders',
  },
]

const DashCard = () => {
  const records = useQueryStakeInfo()

  return (
    <div className='container-fluid mt-2 '>
      <div className='row'>
        {cards.map((data) => (
          <div key={data.key} className='col-md-3 col-sm-6 mb-3'>
            <div
              className='card dashboard-card bg-secondary-subtle'
              style={{
                display: 'flex',
                gap: '105px',
                justifyContent: 'space-evenly',
                marginLeft: '',
              }}
            >
              <div className='card-body text-center'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  <i className={data.imgClass}></i>
                  <h5
                    className='card-title text-center'
                    style={{
                      fontSize: ' 12px',
                      paddingTop: '8px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '0.36px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {data.name}
                  </h5>
                </div>

                <p
                  className='card-text text-center'
                  style={{
                    fontSize: ' 18px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    letterSpacing: '0.36px',
                    textTransform: 'uppercase',
                  }}
                >
                  {records[data.key] ? Number(records[data.key]).toLocaleString() : 0}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DashCard
