import axios, {AxiosResponse} from 'axios'
import {CashierQueryResponse, Filter, NetRevenuRecord, UncliamedRecord} from './_models'
import FileSaver from 'file-saver'
import {CashierModel} from '../../../retailers/core/_models'
import {Response} from '../../../../../_metronic/helpers'

const API_URL = import.meta.env.REACT_APP_API_URL

const getCashiers = (): Promise<CashierQueryResponse> => {
  return axios
    .get(`${API_URL}/cashiers`)
    .then((res: AxiosResponse<Response<Array<CashierModel>>>) => {
      return res.data
    })
}

const getNetRevenuRecords = (
  filter: Filter,
  query: string
): Promise<AxiosResponse<{data: NetRevenuRecord[]; payload: any}> | undefined> => {
  return axios
    .post(`${API_URL}/reports?${query}`, {...filter})
    .then((d) => {
      return {data: d.data} as AxiosResponse<{data: NetRevenuRecord[]; payload: any}>
    })
    .catch((e) => {
      console.error(e)
      return undefined
    })
}

const getUncliamedRecords = (
  filter: Filter,
  query: string
): Promise<AxiosResponse<{data: UncliamedRecord[]; payload: any}> | undefined> => {
  return axios
    .post(`${API_URL}/reports/unclaimed?${query}`, {...filter})
    .then((d) => {
      return {data: d.data} as AxiosResponse<{data: UncliamedRecord[]; payload: any}>
    })
    .catch((e) => {
      console.error(e)
      return undefined
    })
}

// const getUncliamedRecords = (
//   filter: Filter,
//   query: string
// ): Promise<AxiosResponse<UncliamedRecord[]> | undefined> => {
//   return axios
//     .get(`${API_URL}/reports/unclaimed?${query}`, {params: {...filter}})
//     .then((res) => {
//       return res
//     })
//     .catch((e) => undefined)
// }

const exportReport = (filter: Filter): Promise<void> => {
  return axios
    .post(`${API_URL}/reports/export`, {...filter}, {responseType: 'blob'})
    .then((res) => {
      const contentDispositionHeader = res.headers['content-disposition']
      const fileNameMatch =
        contentDispositionHeader && contentDispositionHeader.match(/filename="(.+)"/)
      const fileName = fileNameMatch ? fileNameMatch[1] : 'exported-file.xlsx'
      FileSaver.saveAs(res.data, fileName)
    })
    .catch((e) => {
      console.error(e)
    })
}

export {getNetRevenuRecords, getUncliamedRecords, getCashiers, exportReport}
