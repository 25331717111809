import {SuperAdminDashboardModel} from '../../core/_models'
import {TableTemplate} from './tableTemplate'

type SuperAdminTableProps = {
  data: SuperAdminDashboardModel[]
}

export function SuperAdminTable(props: SuperAdminTableProps) {
  const {highestPerformingOrganizations} = props.data[0]

  return (
    <TableTemplate heading={['No.', 'Company', 'Bet Amount', 'Slip Count']}>
      {highestPerformingOrganizations?.map(({company_name, total_stake, receipt_count}, i) => (
        <tr key={i}>
          <td className='text-start min-w-50px'>{i + 1}</td>
          <td className='text-start min-w-50px'>
            <span>{company_name}</span>
          </td>
          <td className='text-start min-w-50px'>
            <span>{total_stake}</span>
          </td>
          <td className='text-start min-w-50px'>
            <span>{receipt_count}</span>
          </td>
        </tr>
      ))}
    </TableTemplate>
  )
}
