export const formatDate = (inputDate: Date) => {
  const date = new Date(inputDate)

  const day = date.getDate()
  const month = date.getMonth() + 1 // Months are zero-based, so add 1
  const year = date.getFullYear()

  // Ensure the components have two digits (i.e, '05' instead of '5')
  const formattedDay = String(day).padStart(2, '0')
  const formattedMonth = String(month).padStart(2, '0')

  // Format the date as 'dd/mm/yyyy'
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`

  return formattedDate
}
