import React, {useState} from 'react'
import {useQuery} from 'react-query'

import {PageTitle} from '../../../_metronic/layout/core'
import {RangeType} from './types/trackingBills.types'
import {DisableSidebar} from '../../../_metronic/layout/core'
import {useAuth} from '../auth'
import {TrackingBiilsModel} from './core/_models'

import Pagination from './components/pagination'
import ClaimPaymentModalForm from './claimPayment/claimPaymentModalForm'
import ClaimPaymentModalDetail from './claimPayment/claimPaymentModalDetail'
import CompanyFilterByDate from './companyFilter/companyFilterByDate'
import CompanyFilterByName from './companyFilter/companyFilterByName'

import {Days, Card, Table} from './components/index'

const tempData: TrackingBiilsModel = {
  duePayment: 274000,
  duePaymentTicketCount: 530,
  recentPayment: 200000,
  recentPaymentTicketCount: 298,
  totalPayment: 274000,
  totalPaymentTicketCount: 2700,
  billsCollectedTableData: [
    {
      bills_from: 'Marathon Betting',
      amount: 12000,
      collected_by: 'Anteneh Birhanu',
      claimed_date: new Date(),
      status: 'Collected',
    },
    {
      bills_from: 'King Betting LLc',
      amount: 12000,
      collected_by: 'Anteneh Birhanu',
      claimed_date: new Date(),
      status: 'Collected',
    },
    {
      bills_from: 'Quick Win Betting',
      amount: 12000,
      collected_by: 'Anteneh Birhanu',
      claimed_date: new Date(),
      status: 'Collected',
    },
    {
      bills_from: 'Kan Betting LLC',
      amount: 12000,
      collected_by: 'Anteneh Birhanu',
      claimed_date: new Date(),
      status: 'Collected',
    },
    {
      bills_from: 'Ablaze Betting',
      amount: 12000,
      collected_by: 'Anteneh Birhanu',
      claimed_date: new Date(),
      status: 'Collected',
    },
  ],
}

function TrackingBills({range}: {range: RangeType}) {
  // const {} = useQuery();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false)
  const [currentCompany, setCurrentCompany] = useState<string>('All Companies')
  const [currentPage, setCurrentPage] = useState<number>(1)

  // Open/Close Detail Modal Handler
  const detailModalOpenHandler = () => {
    setIsDetailModalOpen(!isDetailModalOpen)
  }

  // Open/Close Form Modal Handler
  const modalOpenHandler = () => {
    setIsModalOpen(!isModalOpen)
  }

  // Load Table data Handler Based On The Given Page
  const loadPageHandler = (page: number) => {
    setCurrentPage(page)
  }

  // Handle Company Filter Click
  const handleCompanyFilterClick = (company: string) => {
    setCurrentCompany(company)
  }

  const cardData = [
    {
      title: 'Due Payment',
      imgSrc: 'red.svg',
      payment: tempData.duePayment,
      payment_ticket_count: tempData.duePaymentTicketCount,
    },
    {
      title: 'Recent Payment',
      imgSrc: 'blue.svg',
      payment: tempData.recentPayment,
      payment_ticket_count: tempData.recentPaymentTicketCount,
    },
    {
      title: 'Total Paid Amount',
      imgSrc: 'purple-1.svg',
      payment: tempData.totalPayment,
      payment_ticket_count: tempData.totalPaymentTicketCount,
    },
  ]

  return (
    <>
      <ClaimPaymentModalForm isModalOpen={isModalOpen} onModalClose={modalOpenHandler} />
      <ClaimPaymentModalDetail
        isModalOpen={isDetailModalOpen}
        onModalOpen={detailModalOpenHandler}
        // data={tempData}
      />
      <div className='row gy-3 g-xl-10 p-0'>
        {cardData.map(({title, imgSrc, payment, payment_ticket_count}, i) => (
          <Card
            key={i}
            payment={payment}
            per_ticket={payment_ticket_count}
            title={title}
            imgSrc={imgSrc}
          />
        ))}
        <div className='d-flex justify-content-between'>
          <h1>Recent Bills Collected</h1>
          <button type='button' className='btn btn-primary' onClick={modalOpenHandler}>
            Claim Payment
          </button>
        </div>
        <div className='pt-4 d-flex justify-content-center gap-4' style={{zIndex: 9}}>
          <CompanyFilterByDate active_date='Oct 23' />
          <CompanyFilterByName
            data={tempData.billsCollectedTableData}
            current_company={currentCompany}
            clicked={handleCompanyFilterClick}
          />
        </div>
        <div className='row gy-3'>
          <div className='col-sm-12'>
            <Table
              data={tempData}
              onDetailModalOpen={detailModalOpenHandler}
              collected_bill_per_page={4}
            />
          </div>
        </div>
        <Pagination
          data={tempData.billsCollectedTableData || []}
          itemsPerPage={4}
          clicked={loadPageHandler}
          current_page={currentPage}
        />
      </div>
    </>
  )
}

const TrackingBillsWrapper = () => {
  const [range, setRange] = useState<RangeType>('TODAY')

  const handleCardToolbarClick = (event: React.MouseEvent<HTMLDivElement>, value: RangeType) => {
    event.preventDefault()
    setRange(value)
  }

  const {currentUser} = useAuth()

  return (
    <DisableSidebar>
      <PageTitle description='' breadcrumbs={[]}>
        {`Hello, ${currentUser?.name}`}
      </PageTitle>

      <Days activeDay={range} clicked={handleCardToolbarClick} range={range} />
      <TrackingBills range={range} />
    </DisableSidebar>
  )
}

export {TrackingBillsWrapper}
