import React, {ReactNode} from 'react'

type ModalProps = {
  isModalOpen: boolean
  onCloseModal: () => void
  children: ReactNode
  type: 'FORM_MODAL' | 'DETAIL_MODAL'
}

const Modal = (props: ModalProps) => {
  const {isModalOpen, onCloseModal, children, type} = props

  return isModalOpen ? (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_organization'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='exampleModalToggleLabel2'>
                Claim Payment
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={onCloseModal}
              ></button>
            </div>
            <div className='modal-body'>{children}</div>
            <div className='modal-footer d-flex justify-content-center gap-4'>
              {type === 'FORM_MODAL' ? (
                <>
                  <button
                    className='btn text-primary fw-bold bg-primary bg-opacity-10'
                    type='button'
                    data-bs-toggle='modal'
                    onClick={onCloseModal}
                  >
                    Discard
                  </button>
                  <button
                    className='btn btn-primary'
                    data-bs-target='#exampleModalToggle'
                    data-bs-toggle='modal'
                  >
                    Claim Payment
                  </button>
                </>
              ) : (
                <button
                  className='btn text-primary fw-bold bg-primary bg-opacity-10'
                  type='button'
                  data-bs-toggle='modal'
                  onClick={onCloseModal}
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  ) : null
}

export default Modal
