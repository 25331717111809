import {
  systemMode,
  ThemeModeType,
} from '../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const Theme = {
  light: '#f1f5f9',
  dark: '#232334',
  system: systemMode === 'light' ? '#f1f5f9' : '#232334',
}

export const selectComponentStyle = (mode: ThemeModeType) => ({
  selectStyle: {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: Theme[mode],
      border: 'none',
    }),
  },
  themes: (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: mode === 'dark' ? '#464661' : '#dee2e6', // text color
      primary25: mode === 'dark' ? '#666681' : '#d9d9d9', // hover bg color
      neutral0: mode === 'dark' ? '#151521' : '#f9f9f9', // input bg color
      neutral20: mode === 'dark' ? '#d9d9d9' : '#d9d9d9', // dropdown icon color
      neutral80: mode === 'dark' ? '#666681' : '#555', // selected color
    },
  }),
})
