import {useState} from 'react'
import {KTCard} from '../../../../../_metronic/helpers'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {FailedReportsForm} from '../FailedReportsForm'
import {ReceiptReportsForm} from './ReceiptReportForm'
import {ListViewProvider} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {RecordsTable} from '../table/ReportsTable'
import {TabHeader} from './TabHeader'
import {TicketWrapper} from './TicketWrapper'
import {SlipDetailResponse} from '../core/_models'
import DashCard from '../cards/DashCard'
type TabKey = 'slip_detail' | 'slip_report'

function SlipManagementPages() {
  const [activeTab, setActiveTab] = useState<TabKey>('slip_detail')

  const handleTabClick = (tabKey: TabKey) => {
    setActiveTab(tabKey)
  }
  const [ticketData, setTicketData] = useState<SlipDetailResponse>()

  return (
    <>
      <PageTitle>{activeTab === 'slip_detail' ? 'Slip Detail' : 'Slip Report'}</PageTitle>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider>
            <KTCard>
              <TabHeader activeTab={activeTab} onTabClick={handleTabClick} />
              {activeTab === 'slip_detail' && (
                <div>
                  <ReceiptReportsForm setTicketInfo={setTicketData} />

                  <hr />
                  <TicketWrapper ticketInfo={ticketData} />
                </div>
              )}
              {activeTab === 'slip_report' && (
                <div>
                  <FailedReportsForm />
                  <DashCard />
                  <hr />
                  <RecordsTable />
                </div>
              )}
            </KTCard>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {SlipManagementPages}
