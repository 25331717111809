/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import {useFormik} from 'formik'
import {useState} from 'react'
import * as Yup from 'yup'
import {useAuth} from '../core/Auth'
import {getUserByToken, login} from '../core/_requests'
import damaLogo from '/media/logos/dama-logo-dark.png'
import clsx from 'clsx'
import {KTIcon} from '../../../../_metronic/helpers'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required')
    .matches(
      RegExp('^[a-zA-Z0-9_.-]{3,30}$'),
      'Username must only contain letters, numbers and/or underscore, period or hyphen'
    ),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  // rememberMe: Yup.boolean().optional()
})

const initialValues = {
  username: '',
  password: '',
  rememberMe: false,
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting, setErrors}) => {
      setLoading(true)
      setStatus(undefined)
      try {
        const {data: auth} = await login(values.username, values.password, 'admin')
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.accessToken)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        if (axios.isAxiosError(error)) {
          error.response
            ? ['password', 'username'].includes(error.response?.data?.property)
              ? setErrors({[error.response?.data?.property]: error.response?.data?.message})
              : setStatus(error.response?.data?.message)
            : setStatus('Something went wrong. Please check your connection.')
        } else {
          setStatus(
            'Something went wrong. Please check your connection or contact the administrators.'
          )
        }
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div style={{height: '100vh', width: '100vw', backgroundColor: 'black'}}>
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
        className='px-5 position-relative d-flex flex-column justify-content-center align-items-center'
        style={{backgroundColor: '#F3F6F9', height: '100%'}}
      >
        {/*Small screen*/}
        <div className='d-flex d-md-none flex-column justify-content-center align-items-center rounded-1 w-100'>
          <img
            className='d-flex justify-content-center'
            src={damaLogo}
            alt='dama logo'
            style={{padding: '0 0 2rem 0', width: '15rem'}}
          />
          {formik.status && (
            <div
              className='alert text-danger font-weight-bold text-start'
              style={{background: 'transparent', padding: '0 0', width: '100%'}}
              role='alert'
            >
              {formik.status}
            </div>
          )}
          <div className='d-flex flex-column w-100'>
            <label
              htmlFor='username'
              style={{
                fontSize: '1rem',
                color: '#464E5F',
                fontWeight: 600,
                padding: '0.5rem 0',
              }}
            >
              Username
              <span className='required' />
            </label>
            <input
              id='username_sm'
              type='username'
              autoComplete='off'
              placeholder='Username'
              {...formik.getFieldProps('username')}
              className={clsx(
                {
                  'is-invalid': formik.touched.username && formik.errors.username,
                }
                // {
                //   'is-valid': formik.touched.username && !formik.errors.username,
                // }
              )}
              style={{
                fontSize: '1rem',
                fontWeight: '500',
                color: '#464E5F',
                backgroundColor: 'white',
                border: 'none',
                outline: 'none',
                padding: '1.5rem 0.75rem',
                borderRadius: '8px',
              }}
            />
            <div style={{width: '100%'}}>
              {formik.touched.username && formik.errors.username ? (
                <div className='fv-plugins-message-container mt-1 mb-3'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-capitalize alert-pass'>
                      {formik.errors.username}
                    </span>
                  </div>
                </div>
              ) : (
                <div style={{height: '2rem'}} />
              )}
            </div>
          </div>
          <div className='d-flex flex-column w-100'>
            <label
              htmlFor='password'
              style={{
                fontSize: '1rem',
                color: '#464E5F',
                fontWeight: 600,
                padding: '0.5rem 0',
              }}
            >
              Password
              <span className='required' />
            </label>
            <div className='d-flex w-100 position-relative'>
              <input
                id='password_sm'
                type={isPasswordVisible ? 'text' : 'password'}
                autoComplete='off'
                placeholder='********'
                {...formik.getFieldProps('password')}
                className={clsx(
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  }
                  // {
                  //   'is-valid': formik.touched.username && !formik.errors.username,
                  // }
                )}
                style={{
                  fontSize: '1rem',
                  fontWeight: '500',
                  color: '#464E5F',
                  backgroundColor: 'white',
                  border: 'none',
                  outline: 'none',
                  padding: '1.5rem 0.75rem',
                  borderRadius: '8px',
                  width: '100%',
                }}
              />
              <span
                style={{
                  width: '4rem',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: '0.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                <KTIcon
                  iconName={isPasswordVisible ? 'eye-slash' : 'eye'}
                  iconType='solid'
                  className='fs-2'
                />
              </span>
            </div>
            <div style={{width: '100%'}}>
              {formik.touched.password && formik.errors.password ? (
                <div className='fv-plugins-message-container mt-1 mb-3'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-capitalize alert-pass'>
                      {formik.errors.password}
                    </span>
                  </div>
                </div>
              ) : (
                <div style={{height: '2rem'}} />
              )}
            </div>
          </div>
          {/*<div className='d-flex w-100 py-4'>*/}
          {/*    <input*/}
          {/*        id='rememberMe'*/}
          {/*        type='checkbox'*/}
          {/*        autoComplete='off'*/}
          {/*        placeholder='********'*/}
          {/*        {...formik.getFieldProps('rememberMe')}*/}
          {/*        className={clsx(*/}
          {/*            {*/}
          {/*                'is-invalid': formik.touched.rememberMe && formik.errors.rememberMe,*/}
          {/*            }*/}
          {/*            // {*/}
          {/*            //   'is-valid': formik.touched.username && !formik.errors.username,*/}
          {/*            // }*/}
          {/*        )}*/}
          {/*        style={{*/}
          {/*            fontSize: '0.75rem',*/}
          {/*            fontWeight: '500',*/}
          {/*            color: '#464E5F',*/}
          {/*            backgroundColor: 'white',*/}
          {/*            border: 'none',*/}
          {/*            outline: 'none',*/}
          {/*            padding: '1.5rem 0.75rem',*/}
          {/*            borderRadius: '8px'*/}
          {/*        }}*/}
          {/*    />*/}
          {/*    <label*/}
          {/*        htmlFor='rememberMe'*/}
          {/*        style={{*/}
          {/*            fontSize: '0.75rem',*/}
          {/*            color: '#464E5F',*/}
          {/*            fontWeight: 600,*/}
          {/*            padding: '0.5rem 0.5rem'*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        Remember me*/}
          {/*    </label>*/}
          {/*</div>*/}
          <button
            style={{
              outline: 'none',
              border: 'none',
              padding: '1.5rem 0',
              width: '100%',
              borderRadius: '8px',
              backgroundColor: '#3699FF',
              color: 'white',
              fontSize: '1.25rem',
              fontWeight: 'bold',
            }}
            disabled={loading}
            type='submit'
          >
            {loading ? 'Loading...' : 'Login'}
          </button>
        </div>

        {/*Large screen*/}
        <div
          className='container-fluid d-none d-md-flex flex-column justify-content-center align-items-center bg-white'
          style={{
            maxWidth: '45rem',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
            borderRadius: '1.5rem',
          }}
        >
          <img
            className='d-flex justify-content-center'
            src={damaLogo}
            alt='dama logo'
            style={{padding: '6rem 0 2rem 0', width: '15rem'}}
          />
          {formik.status && (
            <div
              className='alert text-danger font-weight-bold text-start d-flex justify-content-start w-100 p-0'
              role='alert'
            >
              {formik.status}
            </div>
          )}
          <div className='d-flex flex-column w-100'>
            <label
              htmlFor='username'
              style={{
                fontSize: '1.25rem',
                color: '#464E5F',
                fontWeight: 600,
                padding: '0.5rem 0',
              }}
            >
              Username
              <span className='required' />
            </label>
            <input
              id='username'
              type='username'
              autoComplete='off'
              placeholder='Username'
              {...formik.getFieldProps('username')}
              className={clsx(
                {
                  'is-invalid': formik.touched.username && formik.errors.username,
                }
                // {
                //   'is-valid': formik.touched.username && !formik.errors.username,
                // }
              )}
              style={{
                fontSize: '1.25rem',
                fontWeight: '500',
                color: '#464E5F',
                backgroundColor: '#F3F6F9',
                border: 'none',
                outline: 'none',
                padding: '1.5rem 0.75rem',
                borderRadius: '8px',
              }}
            />
            <div style={{width: '100%'}}>
              {formik.touched.username && formik.errors.username ? (
                <div className='fv-plugins-message-container mt-1 mb-3'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-capitalize alert-pass'>
                      {formik.errors.username}
                    </span>
                  </div>
                </div>
              ) : (
                <div style={{height: '2rem'}} />
              )}
            </div>
          </div>
          <div className='d-flex flex-column w-100'>
            <label
              htmlFor='password'
              style={{
                fontSize: '1.25rem',
                color: '#464E5F',
                fontWeight: 600,
                padding: '0.5rem 0',
              }}
            >
              Password
              <span className='required' />
            </label>
            <div className='d-flex w-100 position-relative'>
              <input
                id='password'
                type={isPasswordVisible ? 'text' : 'password'}
                autoComplete='off'
                placeholder='********'
                {...formik.getFieldProps('password')}
                className={clsx(
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  }
                  // {
                  //   'is-valid': formik.touched.username && !formik.errors.username,
                  // }
                )}
                style={{
                  fontSize: '1.25rem',
                  fontWeight: '500',
                  color: '#464E5F',
                  backgroundColor: '#F3F6F9',
                  border: 'none',
                  outline: 'none',
                  padding: '1.5rem 0.75rem',
                  borderRadius: '8px',
                  width: '100%',
                }}
              />
              <span
                style={{
                  width: '4rem',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: '0.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                <KTIcon
                  iconName={isPasswordVisible ? 'eye-slash' : 'eye'}
                  iconType='solid'
                  className='fs-2'
                />
              </span>
            </div>
            <div style={{width: '100%'}}>
              {formik.touched.password && formik.errors.password ? (
                <div className='fv-plugins-message-container mt-1 mb-3'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-capitalize alert-pass'>
                      {formik.errors.password}
                    </span>
                  </div>
                </div>
              ) : (
                <div style={{height: '2rem'}} />
              )}
            </div>
          </div>
          {/*<div className='d-flex w-100 py-4'>*/}
          {/*    <input*/}
          {/*        id='rememberMe'*/}
          {/*        type='checkbox'*/}
          {/*        autoComplete='off'*/}
          {/*        placeholder='********'*/}
          {/*        {...formik.getFieldProps('rememberMe')}*/}
          {/*        className={clsx(*/}
          {/*            {*/}
          {/*                'is-invalid': formik.touched.password && formik.errors.password,*/}
          {/*            }*/}
          {/*            // {*/}
          {/*            //   'is-valid': formik.touched.username && !formik.errors.username,*/}
          {/*            // }*/}
          {/*        )}*/}
          {/*        style={{*/}
          {/*            fontSize: '0.75rem',*/}
          {/*            fontWeight: '500',*/}
          {/*            color: '#464E5F',*/}
          {/*            backgroundColor: '#F3F6F9',*/}
          {/*            border: 'none',*/}
          {/*            outline: 'none',*/}
          {/*            padding: '1.5rem 0.75rem',*/}
          {/*            borderRadius: '8px'*/}
          {/*        }}*/}
          {/*    />*/}
          {/*    <label*/}
          {/*        htmlFor='rememberMe'*/}
          {/*        style={{*/}
          {/*            fontSize: '1.0rem',*/}
          {/*            color: '#464E5F',*/}
          {/*            fontWeight: 600,*/}
          {/*            padding: '0.5rem 0.5rem'*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        Remember me*/}
          {/*    </label>*/}
          {/*</div>*/}
          <button
            style={{
              outline: 'none',
              border: 'none',
              padding: '1.5rem 0',
              width: '100%',
              borderRadius: '8px',
              backgroundColor: '#3699FF',
              color: 'white',
              fontSize: '1.25rem',
              fontWeight: 'bold',
              marginBottom: '4rem',
            }}
            disabled={loading}
            type='submit'
          >
            {loading ? 'Loading...' : 'Login'}
          </button>
        </div>
      </form>
    </div>
  )
}
