import {ReactNode} from 'react'
import {AdminDashboardModel} from '../../core/_models'
import {CardTemplate} from './cardTemplate'
import {RangeType} from '../../types/dashboard.types'
import {calculateTargetDate} from '../CalculatedDate'
import {format, subMonths} from 'date-fns'

type AdminProps = {
  data: AdminDashboardModel[]
  title: string
  imgSrc: string
  range: RangeType
}

const changeRTP = (rtp: string | undefined, rtpChange: string | undefined) => {
  if (rtpChange && +rtpChange === 0) {
    return `${rtpChange}%`
  } else if (rtpChange && +rtpChange > 0) {
    return `${rtpChange}% Higher`
  } else {
    return `${rtpChange && +rtpChange * -1}% Lower`
  }
}

export function AdminCard(props: AdminProps) {
  const {data, title, imgSrc, range} = props

  const {
    activeShopCount,
    net,
    payIn,
    // payInTicketCount,
    payOut,
    // payOutTicketCount,
    shopCount,
    // netTicketCount,
    unclaimed,
    // unclaimedTicketCount,
    // rtp,
    // rtpChange,
    rtpKeno,
    rtpKenoChange,
    rtpSpin,
    rtpSpinChange,
    rtpRunner,
    rtpRunnerChange,
    rtpBingo,
    rtpBingoChange,
  } = data[0]

  let temp: ReactNode

  const targetDate = calculateTargetDate(range)

  const isCurrentMonth = targetDate.getMonth() === new Date().getMonth()
  // console.log(subMonths(targetDate, 0), 'MMM')

  const formattedDate = isCurrentMonth
    ? format(targetDate, 'MMM')
    : format(subMonths(targetDate, 0), 'MMM')

  switch (title) {
    case 'Pay-In':
      temp = (
        <>
          <div className='h3 pb-8'>{title}</div>
          <div className='h1'>{payIn} ETB</div>
          {/* <div className='text-muted h6 pt-2 fw-normal fs-4'>{payInTicketCount} Tickets</div> */}
        </>
      )
      break
    case 'Pay-Out':
      temp = (
        <>
          <div className='h3 pb-8'>{title}</div>
          <div className='h1'>{payOut} ETB</div>
          {/* <div className='text-muted h6 pt-2 fw-normal fs-4'>{payOutTicketCount} Tickets</div> */}
        </>
      )
      break
    case 'Unclaimed':
      temp = (
        <>
          <div className='h3 pb-8'>{title}</div>
          <div className='h1'>{Number(unclaimed) ? unclaimed : '0'} ETB</div>
          {/* <div className='text-muted h6 pt-2 fw-normal fs-4'>{unclaimedTicketCount} Tickets</div> */}
        </>
      )
      break
    case 'Created Shops':
      temp = (
        <>
          <div className='h3 pb-8'>{title}</div>
          <div className='h1'>{shopCount}</div>
          <div className='text-muted h6 pt-2 fw-normal fs-4'>{activeShopCount} Active</div>
        </>
      )
      break
    case 'Net':
      temp = (
        <>
          <div className='h3 pb-8'>{title}</div>
          <div className='h1'>{net} ETB</div>
          {/* <div className='text-muted h6 pt-2 fw-normal fs-4'>{netTicketCount} Tickets</div> */}
        </>
      )
      break
    case 'RTP/MAR Keno':
      temp = rtpKeno && (
        <>
          <div className='h3 pb-8'>RTP/{formattedDate}</div>
          <div className='h1'>{`${rtpKeno}%`}</div>
          <div className='text-muted h6 pt-2 fw-normal fs-4'>
            {/* {rtpChange === 0
              ? `${rtpChange}%`
              : rtpChange > 0
              ? `${rtpChange}% Higher`
              : `${rtpChange * -1}% Lower`} */}
            {changeRTP(rtpKeno, rtpKenoChange)}
          </div>
        </>
      )
      break
    case 'RTP/MAR Spin And Win':
      temp = rtpSpin && (
        <>
          <div className='h3 pb-8'>RTP/{formattedDate}</div>
          <div className='h1'>{`${rtpSpin}%`}</div>
          <div className='text-muted h6 pt-2 fw-normal fs-4'>
            {changeRTP(rtpSpin, rtpSpinChange)}
          </div>
        </>
      )
      break
    case 'RTP/MAR Bingo':
      temp = rtpBingo && (
        <>
          <div className='h3 pb-8'>RTP/{formattedDate}</div>
          <div className='h1'>{`${rtpBingo}%`}</div>
          <div className='text-muted h6 pt-2 fw-normal fs-4'>
            {changeRTP(rtpBingo, rtpBingoChange)}
          </div>
        </>
      )
      break
    case 'RTP/MAR Runner':
      temp = rtpRunner && (
        <>
          <div className='h3 pb-8'>RTP/{formattedDate}</div>
          <div className='h1'>{`${rtpRunner}%`}</div>
          <div className='text-muted h6 pt-2 fw-normal fs-4'>
            {changeRTP(rtpRunner, rtpRunnerChange)}
          </div>
        </>
      )
      break
    default:
      temp = (
        <>
          <div className='h1'>{payIn} ETB</div>
          {/* <div className='text-muted h6 pt-2 fw-normal fs-4'>{payInTicketCount} Tickets</div> */}
        </>
      )
  }

  return temp ? <CardTemplate imgSrc={imgSrc}>{temp}</CardTemplate> : null
}
