import {useEffect, useRef, useState} from 'react'
import styles from './select.module.css'

export type SelectOption = {
  label: string
  value: string | number
  background: string
}

type MultipleSelectProps = {
  multiple: true
  value: SelectOption[]
  onChange: (value: SelectOption[]) => void
}

type SingleSelectProps = {
  multiple?: false
  value?: SelectOption
  onChange: (value: SelectOption | undefined) => void
}

type SelectProps = {
  options: SelectOption[]
} & (SingleSelectProps | MultipleSelectProps)

export function Select({multiple, value, onChange, options}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState(0)

  const containerRef = useRef<HTMLDivElement>(null)

  const [selectOptions, setSelectOptions] = useState<SelectOption[]>(options)

  const clearOptions = () => {
    if (multiple) {
      onChange([])
      setSelectOptions(options)
    } else {
      onChange(undefined)
    }
  }

  function selectOption(option: SelectOption) {
    if (multiple) {
      if (value.includes(option)) {
        onChange(value.filter((e) => e !== option))

        setSelectOptions((prevOptions) => [...prevOptions, option])
      } else {
        onChange([...value, option])

        setSelectOptions((prevOptions) => prevOptions.filter((e) => e !== option))
      }
    } else {
      if (option !== value) onChange(option)
    }
  }

  function isOptionSelected(option: SelectOption) {
    return multiple ? value.includes(option) : option === value
  }

  useEffect(() => {
    if (isOpen) setHighlightedIndex(0)
  }, [isOpen])

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.target != containerRef.current) return
      switch (e.code) {
        case 'Enter':
        case 'Space':
          setIsOpen((prev) => !prev)
          if (isOpen) selectOption(options[highlightedIndex])
          break
        case 'ArrowUp':
        case 'ArrowDown': {
          if (!isOpen) {
            setIsOpen(true)
            break
          }

          const newValue = highlightedIndex + (e.code === 'ArrowDown' ? 1 : -1)
          if (newValue >= 0 && newValue < options.length) {
            setHighlightedIndex(newValue)
          }
          break
        }
        case 'Escape':
          setIsOpen(false)
          break
      }
    }
    containerRef.current?.addEventListener('keydown', handler)

    return () => {
      containerRef.current?.removeEventListener('keydown', handler)
    }
  }, [isOpen, highlightedIndex, options])

  return (
    <div
      ref={containerRef}
      onBlur={() => setIsOpen(false)}
      onClick={() => setIsOpen((prev) => !prev)}
      tabIndex={0}
      className={styles.container}
    >
      <span className={styles.value}>
        {multiple
          ? value.map((v) => (
              <button
                key={v.value}
                onClick={(e) => {
                  e.stopPropagation()
                  selectOption(v)
                }}
                className={styles['option-badge'] + ' badge text-secondary-subtle'}
                style={{
                  background: v.background,
                  border: 'none',
                  padding: '5px',
                }}
              >
                {v.label}
                <span className={styles['remove-btn']}>x</span>
              </button>
            ))
          : value?.label}
      </span>
      <button
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          clearOptions()
        }}
        className={styles['clear-btn']}
      >
        &times;
      </button>
      <div className={styles.divider}></div>
      <div className={styles.caret}></div>
      {isOpen ? (
        <ul className={`${styles.options} bg-secondary-subtle w-100 ${isOpen ? styles.show : ''}`}>
          {selectOptions.map((option, index) => (
            <div
              style={{
                width: '100%',
                borderBottom: '1px solid #F3F6F9',
                display: 'flex',
                paddingTop: '4px',
                justifyContent: 'space-between',
              }}
              key={option.value}
            >
              <li
                onClick={(e) => {
                  e.stopPropagation()
                  selectOption(option)
                  setIsOpen(false)
                }}
                onMouseEnter={() => setHighlightedIndex(index)}
                key={option.value}
                className={`text-secondary-subtle badge ${styles.option} ${
                  isOptionSelected(option) ? styles.selected : ''
                } ${index === highlightedIndex ? styles.highlighted : ''}`}
                style={{
                  background: option.background,
                  padding: '7px',
                  fontFamily: 'poppins',

                  fontSize: '12px',

                  fontWeight: 600,
                  lineHeight: 'normal',
                  letterSpacing: '0.36px',
                }}
              >
                {option.label}
              </li>
              {isOptionSelected(option) && (
                <span>
                  <i className='fa fa-check text-primary'></i>
                </span>
              )}
            </div>
          ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  )
}
