import axios, {AxiosResponse} from 'axios'
import {ID, QueryState, Response, SearchResponse} from '../../../../../../_metronic/helpers'
import {SearchQueryResponse, Agent, AgentsQueryResponse} from './_models'
import {toast} from 'react-toastify'

const API_URL = import.meta.env.REACT_APP_API_URL
const AGENT_URL = `${API_URL}/agents`
const GET_AGENTS_URL = `${API_URL}/agents`
const SEARCH_URL = `${API_URL}/search`
const model = 'Agent'

const searchAgents = (query: Partial<QueryState>): Promise<SearchQueryResponse> => {
  return axios
    .post(`${SEARCH_URL}`, {indexName: 'agents', ...query})
    .then((d: AxiosResponse<SearchResponse<Array<Agent>>>) => {
      return d.data
    })
}

const getAgents = (query: string): Promise<AgentsQueryResponse> => {
  return axios
    .get(`${GET_AGENTS_URL}?${query}`)
    .then((d: AxiosResponse<Response<Array<Agent>>>) => d.data)
}

const getAgentById = (id: ID): Promise<Agent | undefined> => {
  return axios.get(`${AGENT_URL}/${id}`).then((response: AxiosResponse<Agent>) => response.data)
  // .then((response: Response<Agent>) => response.data)
}

const createAgent = (agent: Agent): Promise<Agent | undefined> => {
  return axios
    .post(AGENT_URL, agent)
    .then((response: AxiosResponse<Response<Agent>>) => {
      if (String(response.status).startsWith('2')) toast.success(`${model} created successfully`)
      else toast.error(`Create ${model} failed`)
      return response.data
    })
    .then((response: Response<Agent>) => response.data)
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          'Something went wrong'
      )
      throw error
      // return undefined
    })
}

const updateAgent = (agent: Agent): Promise<Agent | undefined> => {
  return axios
    .patch(`${AGENT_URL}/${agent.id}`, agent)
    .then((response: AxiosResponse<Response<Agent>>) => {
      if (String(response.status).startsWith('2')) toast.success(`${model} updated successfully`)
      else toast.error(`Update ${model} failed`)
      return response.data
    })
    .then((response: Response<Agent>) => response.data)
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          'Something went wrong'
      )
      throw error
      // return undefined
    })
}

const deleteAgent = (agentId: ID): Promise<void> => {
  return axios
    .delete(`${AGENT_URL}/${agentId}`)
    .then((res) => {
      if (String(res.status).startsWith('2')) toast.success(`${model} deleted successfully`)
      else toast.error(`Delete ${model} failed`)
    })
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          'Something went wrong'
      )
      throw error
    })
}

const deleteSelectedAgents = (agentIds: Array<ID>): Promise<void> => {
  const requests = agentIds.map((id) => axios.delete(`${AGENT_URL}/${id}`))
  return axios
    .all(requests)
    .then((res) => {
      if (String(res[0].status).startsWith('2')) toast.success(`${model} deleted successfully`)
      else toast.error(`Delete ${model} failed`)
    })
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          'Something went wrong'
      )
      throw error
    })
}

export {
  getAgents,
  deleteAgent,
  deleteSelectedAgents,
  getAgentById,
  createAgent,
  updateAgent,
  searchAgents,
}
