import {ThemeModeSwitcher} from '../../../partials'
import {NotificationBell} from '../../../partials/layout/notification/NotificationBell'

const Topbar = () => {
  return (
    <div className='d-flex align-items-center flex-shrink-0'>
      {/* begin::Theme mode */}
      <div className={'d-flex align-items-center ms-3 ms-lg-4 gap-4'}>
        {/*<NotificationBell />*/}
        <ThemeModeSwitcher toggleBtnClass='btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px' />
      </div>
      {/* end::Theme mode */}
    </div>
  )
}

export {Topbar}
