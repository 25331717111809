import {FC} from 'react'
import {formatDistance} from 'date-fns'

type Props = {
  last_login?: string
}

const UserLastLoginCell: FC<Props> = ({last_login}) => (
  <div className='badge badge-light fw-bolder'>
    {last_login && formatDistance(new Date(last_login), new Date(), {addSuffix: true})}
  </div>
)

export {UserLastLoginCell}
