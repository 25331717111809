import {BillsCollected} from '../core/_models'

type PaginationProps = {
  data: BillsCollected[]
  itemsPerPage: number
  current_page: number
  clicked: (page: number) => void
}

const Pagination = (props: PaginationProps) => {
  const {data, itemsPerPage, current_page, clicked} = props

  // let totalPages = Math.ceil(data.length / itemsPerPage)
  // if (data.length < itemsPerPage) {
  //   totalPages = data.length
  // }

  const totalPages = 15

  const renderPageNumbers = () => {
    const pageNumbers = []
    // Maximum number of page buttons to display
    const MAX_PAGE_BUTTONS = 5

    // If there are less than 5 pages, display all page buttons
    if (totalPages <= MAX_PAGE_BUTTONS) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li className='page-item bg-primary bg-opacity-5 list-group-item rounded' key={i}>
            <button
              className={`page-link opacity-75 ${i === current_page ? 'text-primary' : ''}`}
              onClick={() => clicked(i)}
            >
              {i}
            </button>
          </li>
        )
      }
      // If there are more than 5 pages, display the first 2, last 2, and current page
    } else {
      const start = Math.max(1, current_page - 2)
      const end = Math.min(totalPages - 1, start + MAX_PAGE_BUTTONS - 1)

      // Display the first page button if not on the first page
      if (start > 1) {
        pageNumbers.push(
          <li className='page-item list-group-item' key='start'>
            <span className='page-link'>&hellip;</span>
          </li>
        )
      }

      // Display the page buttons
      for (let i = start; i <= end; i++) {
        pageNumbers.push(
          <li className='page-item bg-primary bg-opacity-5 list-group-item rounded' key={i}>
            <button
              className={`page-link opacity-75 ${i === current_page ? 'text-primary' : ''}`}
              onClick={() => clicked(i)}
            >
              {i}
            </button>
          </li>
        )
      }

      // Display the last page button if not on the last page
      if (end < totalPages - 1) {
        pageNumbers.push(
          <li className='page-item list-group-item' key='end'>
            <span className='page-link'>&hellip;</span>
          </li>
        )
      }
    }
    return pageNumbers
  }

  // Logic for displaying current loaded table data
  const itemsToDisplay = data.slice((current_page - 1) * itemsPerPage, current_page * itemsPerPage)

  return (
    <nav aria-label='List of betting companies' className='d-flex justify-content-end'>
      <ul className='d-flex '>
        {renderPageNumbers()}
        {data.length > 3 ? (
          <li className='page-item bg-primary bg-opacity-5 list-group-item rounded'>
            <button
              className={`page-link opacity-75 ${
                totalPages === current_page + 1 ? 'text-primary' : ''
              }`}
              onClick={() => clicked(totalPages)}
            >
              {totalPages}
            </button>
          </li>
        ) : null}
      </ul>
    </nav>
  )

  {
    /* Display the items for the current page */
  }
  {
    /* <ul>
    {itemsToDisplay.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>
</div> */
  }
}

export default Pagination
