import {useQueryClient, useMutation} from 'react-query'

import { QUERIES } from '../../../../../../_metronic/helpers'

import { useListView } from '../../../../../modules/organizations/core/ListViewProvider'

import { useQueryResponse } from '../../../../../modules/organizations/core/QueryResponseProvider'
import {deleteSelectedUsers} from '../../core/_requests'

import Swal from 'sweetalert2'


import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'


const SubscriberListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query, refetch} = useQueryResponse()
  const theme = useThemeMode().mode

  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      refetch()
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
  })

  const deleteSelectedRows = () => {
    Swal.fire({
      title: `Are you sure you want to delete these admins?`,
      background: theme === 'light' ? '#fff' : '#232334',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#FF5050',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteSelectedItems.mutateAsync()
      }
    })
  }

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button type='button' className='btn btn-danger' onClick={() => deleteSelectedRows()}>
        Delete Selected
      </button>
    </div>
  )
}

export {SubscriberListGrouping}
