import {Dispatch, FC, SetStateAction, createContext, useContext, useState} from 'react'
import {WithChildren} from '../../helpers'

interface ErrorMessageContextModel {
  message: string | null
  values?: object | null
  setError: (message: string) => void
  clearError: () => void
  setValues: (values: object) => void
  clearValues: () => void
  retry: () => any
  setRetry: Dispatch<SetStateAction<(values: any) => any>>
  clearRetry: () => void
}

const ErrorContext = createContext<ErrorMessageContextModel>({
  message: null,
  values: {},
  setError: () => {},
  clearError: () => {},
  setValues: () => {},
  clearValues: () => {},
  retry: () => {},
  setRetry: () => {},
  clearRetry: () => {},
})

export const ErrorContextProvider: FC<WithChildren> = ({children}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [values, setValues] = useState<object | null>(null)
  const [retry, setRetry] = useState<any>()

  const clearError = () => {
    setErrorMessage(null)
  }

  const clearValues = () => {
    setValues(null)
  }

  const clearRetry = () => {
    setRetry(() => {})
  }

  return (
    <ErrorContext.Provider
      value={{
        message: errorMessage,
        setError: setErrorMessage,
        clearError,
        values,
        setValues,
        clearValues,
        retry,
        setRetry,
        clearRetry,
      }}
    >
      {children}{' '}
    </ErrorContext.Provider>
  )
}

export const useErrorContext = () => {
  return useContext(ErrorContext)
}
