const QUERIES = {
  USERS_LIST: 'users-list',
  ADMIN_LIST: 'admin-list',
  CASHIER_LIST: 'cashier-list',
  AGENTS_LIST: 'agent-list',
  RETAILER_CASHIER_LIST: 'retailer-cashier-list',
  ROLES_LIST: 'roles-list',
  RETAILERS_LIST: 'retailers-list',
  ORGANIZATIONS_LIST: 'organizations-list',
  DASHBOARD: 'dashboard-list',
  NOTIFICATION: 'notification-list',
  JACKPOTS_LIST: 'jackpot',
  ODD_LIST: 'odd-list',
  RETAIL_GAMES_LIST: 'retail-games-list',
  RETAIL_TO_CARTELAS: 'retail-to-cartelas',
}

export {QUERIES}
