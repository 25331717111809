// @ts-nocheck
import {Column} from 'react-table'
import {RecordCustomHeader} from './RecordCustomHeader'
import {SlipRecord} from '../../core/_models'

const BetSlipColumns: ReadonlyArray<Column<SlipRecord>> = [
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='No.' className='text-center min-w-60px' />
    ),
    id: 'number',
    Cell: ({...props}) => {
      return (
        <p id={props.row.index} className='text-center'>
          {props.row.index + 1}
        </p>
      )
    },
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Choices' className='min-w-125px' />
    ),
    accessor: 'choices',
    Cell: ({cell, row}: {cell}) => {
      if (cell.value.type === 'NUMBERS') {
        return `${row.original.game_type.toUpperCase()} ${cell.value.numbers.join(', ')} #${row.original.slip_id}`
      }
      if (cell.value.type === 'HEADS_TAILS') {
        return `${row.original.game_type.toUpperCase()} ${cell.value.choice} #${row.original.slip_id}`
      }
      if (row.original.type) {
        return (
          <div title={`${row.original.game_type.toUpperCase()} ${cell.value.join(', ')}`} data-toggle='tooltip' data-placement='top'>
            Spin & Win {row.original.type} #{row.original.slip_id}
          </div>
        )
      }
    },
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Stake' className='min-w-125px' />
    ),
    accessor: 'stake',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Won Amount' className='min-w-125px' />
    ),
    accessor: 'won_amount',
  },
]

export {BetSlipColumns}
