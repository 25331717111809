import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  queryName,
} from '../core/QueryResponseProvider'
import {recordsColumns} from './columns/_columns'
import {SlipRecord} from '../core/_models'
import {RecordsListLoading} from '../loading/RecordsListLoading'
import {RecordsListPagination} from '../pagination/RecordsListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {useQueryClient} from 'react-query'

const RecordsTable = () => {
  const queryClient = useQueryClient()
  const records = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => records, [records])
  const columns = useMemo(() => recordsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data ?? [],
  })

  const removeData = (id: string) => {
    queryClient.invalidateQueries({queryKey: [queryName]})
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_records'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead style={{position: 'sticky', top: 0, zIndex: 100, padding: '2px'}}>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<SlipRecord>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<SlipRecord>, i) => {
                prepareRow(row)
                return <CustomRow row={row} remove={removeData} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <RecordsListPagination />
      {isLoading && <RecordsListLoading />}
    </KTCardBody>
  )
}

export {RecordsTable}
