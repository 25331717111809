import {FC, useState, createContext, useContext} from 'react'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {Filter} from './_models'
import dayjs from 'dayjs'

function formatted_date(date: Date) {
  return date.toLocaleString('sv').slice(0, 16).replace(' ', 'T')
}

const defaultFilter: Filter = {
  from: formatted_date(dayjs(new Date()).startOf('day').toDate()),
  to: formatted_date(dayjs(new Date()).endOf('day').toDate()),
}

export const initialFilterRequest = {
  filter: defaultFilter,
  updateFilter: (updates: Partial<Filter>) => {},
}

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)
const BodyFilterContext = createContext(initialFilterRequest)

const QueryRequestProvider: FC<WithChildren> = ({children}) => {
  const [state, setState] = useState<QueryState>({...initialQueryRequest.state, items_per_page: 20})
  const [filter, setFilter] = useState<Filter>(initialFilterRequest.filter)

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {...state, ...updates} as QueryState
    setState(updatedState)
  }

  const updateFilter = (updates: Partial<Filter>) => {
    setFilter(updates)
    setState({...initialQueryRequest.state, items_per_page: 20})
  }

  return (
    <BodyFilterContext.Provider value={{filter, updateFilter}}>
      <QueryRequestContext.Provider value={{state, updateState}}>
        {children}
      </QueryRequestContext.Provider>
    </BodyFilterContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
const useBodyFilter = () => useContext(BodyFilterContext)

export {QueryRequestProvider, useQueryRequest, useBodyFilter, BodyFilterContext}
