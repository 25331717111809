import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import {useState} from 'react'
import {
  useThemeMode,
  systemMode,
} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {KTIcon} from '../../../../_metronic/helpers'

type CompanyFilterByDateProps = {
  active_date: string
}

const Theme = {
  light: '#fff',
  dark: '#232334',
  system: systemMode === 'light' ? '#fff' : '#232334',
}

function CompanyFilterByDate(props: CompanyFilterByDateProps) {
  const {active_date} = props
  const parsedDate = new Date(active_date)

  const [selectedDate, setSelectedDate] = useState(parsedDate)
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker)
  }

  // Function to format the date as "Mon Oct 2"
  const formatDate = (date: Date) => {
    const day = date.toLocaleString('en', {month: 'short', day: 'numeric'})

    const dayOfWeekAbbreviation = date.toLocaleDateString('en-US', {
      weekday: 'short',
    })

    // return `${dayOfWeekAbbreviation} ${day}`;
    return {day, dayOfWeekAbbreviation}
  }

  //  Generate a list of years between 1990 to the current year
  const currentYear = new Date().getFullYear()
  const min_year = 2023
  let years: number[] = []
  for (let year = min_year; year <= currentYear; year++) {
    years.push(year)
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const {mode} = useThemeMode()

  const customHeader = ({date, changeYear, changeMonth}: any) => {
    return (
      <div className='d-flex gap-3'>
        <div className='dropdown'>
          <button
            className={`btn dropdown-toggle shadow-sm px-6 py-3 mb-4 rounded d-flex gap-3 align-items-center text-white bg-primary fs-5 fw-bold`}
            type='button'
            style={{backgroundColor: Theme[mode]}}
            data-bs-toggle='dropdown'
            aria-expanded='false'
            data-bs-auto-close='outside'
          >
            {months[new Date(date).getMonth()]}
          </button>
          <ul className='dropdown-menu dropdown-menu-white w-100 mt-2'>
            {months.map((month) => (
              <li key={month}>
                <div
                  className={`dropdown-item fw-bold text-body-secondary `}
                  role='button'
                  onClick={() => changeMonth(months.indexOf(month))}
                >
                  {month}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className='dropdown'>
          <button
            className={`btn dropdown-toggle px-6 py-3 mb-4  d-flex gap-3 align-items-center fs-5 fw-bold text-black`}
            type='button'
            data-bs-toggle='dropdown'
            aria-expanded='false'
            data-bs-auto-close='outside'
          >
            {years[new Date(date).getFullYear()] ?? years[0]}
          </button>
          <ul className='dropdown-menu dropdown-menu-white w-100 mt-2'>
            {years.map((year) => (
              <li key={year}>
                <div
                  className={`dropdown-item fw-bold text-body-secondary `}
                  role='button'
                  onClick={() => changeYear(years.indexOf(year))}
                >
                  {year}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div className='position-relative'>
      <div
        className={`border d-flex justify-content-between h-100 text-body-secondary align-items-center px-4 border-0  shadow-sm rounded `}
        style={{width: '170px', backgroundColor: Theme[mode]}}
        id='external-form'
      >
        <span className='fw-bold fs-5 text-scondary'>
          {formatDate(selectedDate).dayOfWeekAbbreviation}
        </span>
        <span className='text-primary fs-5 fw-bold'>{formatDate(selectedDate).day}</span>
        <div role='button' onClick={toggleDatePicker}>
          <KTIcon iconName='arrow-down' className='cursor-pointer' />
        </div>
      </div>
      {showDatePicker && (
        <DatePicker
          selected={selectedDate}
          dateFormat='d mm'
          inline
          calendarClassName={`${
            mode === 'dark' ? 'bg-dark' : 'bg-white'
          } position-absolute top-100 mt-4 border border-0 p-6 shadow-sm`}
          className='bg-white border-none text-light'
          renderCustomHeader={customHeader}
          onChange={(date: Date) => setSelectedDate(date)}
          highlightDates={[new Date(selectedDate)]}
        />
      )}
    </div>
  )
}

export default CompanyFilterByDate
