import {useFormik} from 'formik'
import {string, object} from 'yup'
import {Modal} from '../components/index'

type InitialValues = {
  company_name: string
  amount_collected: string
  month: string
  remark: string
}

type ClaimPaymentModalFormProps = {
  isModalOpen: boolean
  onModalClose: () => void
}

const schema = object().shape({
  company_name: string().required('Company name is required'),
  amount_collected: string().required('Amount collected is required'),
  month: string().required('Month is required'),
  remark: string().required('Remark is required'),
})

const ClaimPaymentModalForm = (props: ClaimPaymentModalFormProps) => {
  const {isModalOpen, onModalClose} = props

  const initialValues: InitialValues = {
    company_name: '',
    amount_collected: '',
    month: '',
    remark: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (data) => {
      // Handle form submistion here
      console.log(data)
    },
  })

  return (
    <Modal onCloseModal={onModalClose} isModalOpen={isModalOpen} type='FORM_MODAL'>
      <form className='d-flex flex-column gap-8' onSubmit={formik.handleSubmit}>
        <div>
          <label htmlFor='company' className='fs-5 fw-bold mb-2'>
            Company
          </label>
          <select
            className='form-select flex-1 bg-body-secondary border'
            aria-label='Default select example'
            id='company'
            {...formik.getFieldProps('company_name')}
          >
            <option value='0'>Select Company</option>
            <option value='1'>Marathon</option>
            <option value='2'>King</option>
            <option value='3'>Marathon</option>
          </select>
        </div>
        <div className='d-flex justify-content-between gap-3'>
          <div className='w-50'>
            <label htmlFor='amount' className='fs-5 fw-bold mb-2'>
              Amount
            </label>
            <input
              type='text'
              className='form-control bg-body-secondary border'
              aria-label='Dollar amount (with dot and two decimal places)'
              placeholder='100000'
              id='amount'
              {...formik.getFieldProps('amount_collected')}
            />
          </div>
          <div className='w-50'>
            <label htmlFor='month' className='fs-5 fw-bold mb-2'>
              Month
            </label>
            <select
              className='form-select flex-1 bg-body-secondary border'
              aria-label='Default select example'
              id='month'
              {...formik.getFieldProps('month')}
            >
              <option value='0'>Select month</option>
              <option value='1'>Sep.</option>
              <option value='2'>Oct</option>
              <option value='3'>Jun</option>
            </select>
          </div>
        </div>
        <div className='mb-3'>
          <label htmlFor='remark' className='form-label fs-5 fw-bold mb-2'>
            Remark
          </label>
          <textarea
            className='form-control bg-body-secondary border'
            id='remark'
            placeholder='Remarks Here'
            rows={3}
            {...formik.getFieldProps('remark')}
          ></textarea>
        </div>
      </form>
    </Modal>
  )
}

export default ClaimPaymentModalForm
