import {KTCard} from '../../../_metronic/helpers'
import {SubscriberListHeader} from './Subscriber/components/header/SubscriberListHeader'
import {ListViewProvider, useListView} from './Subscriber/core/ListViewProvider'
import {QueryRequestProvider} from './Subscriber/core/QueryRequestProvider'
import {QueryResponseProvider} from './Subscriber/core/QueryResponseProvider'
import {UsersTable} from './Subscriber/table/UsersTable'
import {UserEditModal} from './Subscriber/user-edit-modal/UserEditModal'

const Subscription= () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <SubscriberListHeader />
        <UsersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal />}
    </>
  )
}

const SubscriptionWrapper= () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <Subscription/>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {SubscriptionWrapper}
