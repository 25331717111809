import axios, {AxiosResponse} from 'axios'
import {ID, QueryState, Response, SearchResponse} from '../../../../../_metronic/helpers'
import {SearchQueryResponse, User, UsersQueryResponse} from './_models'
import {toast} from 'react-toastify'

const API_URL = import.meta.env.REACT_APP_API_URL
const ADMIN_URL = `${API_URL}/finances`
const GET_ADMINS_URL = `${API_URL}/finances`
const SEARCH_URL = `${API_URL}/search`
const model = 'User'

const searchUsers = (query: Partial<QueryState>): Promise<SearchQueryResponse> => {
  return axios
    .post(`${SEARCH_URL}`, {indexName: 'finances', ...query})
    .then((d: AxiosResponse<SearchResponse<Array<User>>>) => {
      return d.data
    })
}

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_ADMINS_URL}?${query}`)
    .then((d: AxiosResponse<Response<Array<User>>>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios.get(`${ADMIN_URL}/${id}`).then((response: AxiosResponse<User>) => response.data)
  // .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(ADMIN_URL, user)
    .then((response: AxiosResponse<Response<User>>) => {
      if (String(response.status).startsWith('2')) toast.success(`${model} created successfully`)
      else toast.error(`Create ${model} failed`)
      return response.data
    })
    .then((response: Response<User>) => response.data)
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          `Something went wrong`
      )
      throw error
      // return undefined
    })
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .patch(`${ADMIN_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => {
      if (String(response.status).startsWith('2')) toast.success(`${model} updated successfully`)
      else toast.error(`Update ${model} failed`)
      return response.data
    })
    .then((response: Response<User>) => response.data)
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          `Something went wrong`
      )
      throw error
      // return undefined
    })
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios
    .delete(`${ADMIN_URL}/${userId}`)
    .then((res) => {
      if (String(res.status).startsWith('2')) toast.success(`${model} deleted successfully`)
      else toast.error(`Delete ${model} failed`)
    })
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          `Something went wrong`
      )
      throw error
    })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${ADMIN_URL}/${id}`))
  return axios
    .all(requests)
    .then((res) => {
      if (String(res[0].status).startsWith('2')) toast.success(`${model} deleted successfully`)
      else toast.error(`Delete ${model} failed`)
    })
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          `Something went wrong`
      )
      throw error
    })
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, searchUsers}
