import {AdminDashboardModel} from '../../core/_models'
import {TableTemplate} from './tableTemplate'
import {formatDate} from '../../../../../_metronic/helpers/formatDate'

type AdminTableProps = {
  data: AdminDashboardModel[]
}

export function AdminTable(props: AdminTableProps) {
  const {highestPerformingRetails} = props.data[0]

  return (
    <TableTemplate heading={['No.', 'Retail', 'Bets Amount', 'Slip Count']}>
      {highestPerformingRetails?.map(({total_stake, retail_name, receipt_count}, i) => (
        <tr key={i}>
          <td className='text-start min-w-50px'>{i + 1}</td>
          <td className='text-start min-w-50px'>
            <span>{retail_name}</span>
          </td>
          <td className='text-start min-w-50px'>
            <span>{total_stake}</span>
          </td>
          <td className='text-start min-w-50px'>
            <span>{receipt_count}</span>
          </td>
        </tr>
      ))}
    </TableTemplate>
  )
}
