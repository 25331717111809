// TabHeader.tsx

import React from 'react'

type TabKey = 'slip_detail' | 'slip_report'

interface TabHeaderProps {
  activeTab: TabKey
  onTabClick: (tabKey: TabKey) => void
}

const TabHeader: React.FC<TabHeaderProps> = ({activeTab, onTabClick}) => {
  const tabContainerStyle: React.CSSProperties = {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    padding: '5px',
    marginBottom: '-18px',
  }

  const tabStyle: React.CSSProperties = {
    cursor: 'pointer',
    padding: '5px',
    color: 'black',
    borderBottom: '2px solid transparent',
    transition: 'border-bottom 0.3s ease, color 0.3s ease',
  }

  const hrStyle: React.CSSProperties = {
    height: '1px',
    backgroundColor: 'gray', // Set the horizontal bar color to black
    border: 'none',
    transition: 'margin-left 0.3s ease',
  }

  const activeTabStyle: React.CSSProperties = {
    borderBottomColor: '#3498db',
  }

  const inactiveTabStyle: React.CSSProperties = {
    borderBottomColor: 'transparent',
  }

  return (
    <div className='px-4 py-4'>
      <div style={tabContainerStyle}>
        <a
          className={`tab-link ${activeTab === 'slip_detail' ? 'active' : ''} text-dark`}
          onClick={() => onTabClick('slip_detail')}
          style={{
            ...tabStyle,
            ...(activeTab === 'slip_detail' ? activeTabStyle : inactiveTabStyle),
          }}
        >
          Slip Details
        </a>
        <a
          className={`tab-link ${activeTab === 'slip_report' ? 'active' : ''} text-dark`}
          onClick={() => onTabClick('slip_report')}
          style={{
            ...tabStyle,
            ...(activeTab === 'slip_report' ? activeTabStyle : inactiveTabStyle),
          }}
        >
          Slip Report
        </a>
      </div>
      <hr style={hrStyle} />
    </div>
  )
}

export {TabHeader}
