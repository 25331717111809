/* eslint-disable react-hooks/exhaustive-deps */
// import {FC, useContext, useState, useEffect, useMemo} from 'react'
// import {useQuery} from 'react-query'
// import {
//   createResponseContext,
//   initialQueryResponse,
//   initialQueryState,
//   PaginationState,
//   stringifyRequestQuery,
//   WithChildren,
// } from '../../../../../_metronic/helpers'
// import {getNetRevenuRecords} from './_requests'
// import {NetRevenuRecord} from './_models'
// import {BodyFilterContext, useBodyFilter, useQueryRequest} from './QueryRequestProvider'

// const QueryResponseContext = createResponseContext<NetRevenuRecord>(initialQueryResponse)
// const QueryResponseProvider: FC<WithChildren> = ({children}) => {
//   const {state} = useQueryRequest()
//   const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
//   const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
//   const {filter, updateFilter} = useBodyFilter()
//   const filterString = useMemo(() => JSON.stringify(filter), [filter])

//   useEffect(() => {
//     if (query !== updatedQuery) {
//       setQuery(updatedQuery)
//     }
//   }, [updatedQuery])

//   const {
//     isFetching,
//     refetch,
//     data: response,
//   } = useQuery(
//     `records-list-${filterString}-${query}`,
//     () => {
//       return getNetRevenuRecords(filter, query)
//     },
//     {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
//   )

//   return (
//     <BodyFilterContext.Provider value={{filter, updateFilter}}>
//       <QueryResponseContext.Provider
//         value={{isLoading: isFetching, refetch, response: response?.data, query}}
//       >
//         {children}
//       </QueryResponseContext.Provider>
//     </BodyFilterContext.Provider>
//   )
// }

// const useQueryResponse = () => useContext(QueryResponseContext)

// const useQueryResponseData = () => {
//   const {response} = useQueryResponse()
//   if (!response) {
//     return []
//   }

//   return response?.data || []
// }

// const useQueryResponsePagination = () => {
//   const defaultPaginationState: PaginationState = {
//     links: [],
//     ...initialQueryState,
//   }

//   const {response} = useQueryResponse()
//   if (!response || !response.payload || !response.payload.pagination) {
//     return defaultPaginationState
//   }

//   return response.payload.pagination
// }

// const useQueryResponseLoading = (): boolean => {
//   const {isLoading} = useQueryResponse()
//   return isLoading
// }

// export {
//   QueryResponseProvider,
//   useQueryResponse,
//   useQueryResponseData,
//   useQueryResponsePagination,
//   useQueryResponseLoading,
// }

import {FC, useContext, useState, useEffect, useMemo, ReactNode, createContext} from 'react'
import {useQuery, QueryKey} from 'react-query'
import {AxiosResponse} from 'axios'

import {
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  stringifyRequestQuery,
  Response,
} from '../../../../../_metronic/helpers'
import {getNetRevenuRecords, getUncliamedRecords} from './_requests'
import {NetRevenuRecord, UncliamedRecord} from './_models'
import {BodyFilterContext, useBodyFilter, useQueryRequest} from './QueryRequestProvider'
import {TabKey} from '../../types'

type QueryResContextProps = {
  response?: Response<Array<NetRevenuRecord>> | Response<Array<UncliamedRecord>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

function createResContext(initialState: QueryResContextProps) {
  return createContext(initialState)
}

const QueryResponseContext = createResContext(initialQueryResponse)

const QueryResponseProvider: FC<{children: ReactNode; activeReport: TabKey}> = ({
  children,
  activeReport,
}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {filter, updateFilter} = useBodyFilter()
  const filterString = useMemo(() => JSON.stringify(filter), [filter])

  console.log(filter)

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const getRequestFunction = () => {
    return activeReport === 'Net Revenue' ? getNetRevenuRecords : getUncliamedRecords
  }

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery<
    | AxiosResponse<{data: NetRevenuRecord[]; payload: any}, any>
    | undefined
    | AxiosResponse<{data: UncliamedRecord[]; payload: any}, any>
    | undefined,
    QueryKey
  >(
    `records-list-${activeReport.toLowerCase()}-${filterString}-${query}`,
    async () => {
      const requestFunction = getRequestFunction()
      const responseData = await requestFunction(filter, query)
      return responseData
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    refetch()
  }, [updateFilter])

  return (
    <BodyFilterContext.Provider value={{filter, updateFilter}}>
      <QueryResponseContext.Provider
        value={{isLoading: isFetching, refetch, response: response?.data, query}}
      >
        {children}
      </QueryResponseContext.Provider>
    </BodyFilterContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
