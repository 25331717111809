import {FC} from 'react'

type Props = {
  status?: boolean
}

const UserStatusCell: FC<Props> = ({status}) => (
  <>
    {status ? (
      <div className='badge badge-light-success fw-bolder'>Enabled</div>
    ) : (
      <div className='badge badge-light-danger fw-bolder'>Disabled</div>
    )}
  </>
)

export {UserStatusCell}
