// @ts-nocheck
import {Column} from 'react-table'
import {RecordCustomHeader} from './RecordCustomHeader'
import {Record} from '../../core/_models'

const recordsColumns: ReadonlyArray<Column<Record>> = [
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Shop Name' className='min-w-125px' />
    ),
    accessor: 'retailer',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Cashier Name' className='min-w-125px' />
    ),
    accessor: 'cashier',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Gross Stake' className='min-w-125px' />
    ),
    accessor: 'gross_stake',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Game Type' className='min-w-100px' />
    ),
    accessor: 'game_type',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Net Winnings' className='min-w-125px' />
    ),
    accessor: 'net_winning',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Ticket Number' className='min-w-125px' />
    ),
    accessor: 'ticket_number',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Date' className='min-w-150px' />
    ),
    accessor: 'date',
    Cell: ({...props}) => {
      return (
        <span id={props.row.index} className='text-right'>
          {new Date(props.data[props.row.index].date).toDateString()}
        </span>
      )
    },
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'status',
  },
]

export {recordsColumns}
