import {useListView} from '../../core/ListViewProvider'
import {SubscriberListToolbar} from './SubscriberListToolbar'
import {SubscriberListGrouping} from './SubscriberListGrouping'
import {SubscriberListSearchComponent} from './SubscriberListSearchComponent'
import {PageTitle} from '../../../../../../_metronic/layout/core'

const SubscriberListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <PageTitle>Finance Users</PageTitle>

      <SubscriberListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <SubscriberListGrouping /> : <SubscriberListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {SubscriberListHeader}
