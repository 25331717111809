const DashboardLoading = () => {
  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
  }

  return (
    <div className='d-flex justify-content-center h-500px align-items-center'>
      <div
        style={{...styles, textAlign: 'center'}}
        className='align-self-center align-items-center bg-secondary-subtle'
      >
        Processing...
      </div>
    </div>
  )
}

export {DashboardLoading}
