// @ts-nocheck
import {Column} from 'react-table'
import {RecordCustomHeader} from './RecordCustomHeader'
import {NetRevenuRecord, Records, UncliamedRecord} from '../../core/_models'
import {useQueryResponsePagination} from '../../core/QueryResponseProvider'

const netrevenuRecordsColumns: ReadonlyArray<Column<Records>> = [
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='No.' className='text-left min-w-60px' />
    ),
    id: 'number',
    Cell: ({...props}) => {
      const pagination = useQueryResponsePagination()
      return (
        <span id={props.row.index} className='text-right'>
          {props.row.index === props.rows.length - 1
            ? ''
            : pagination.items_per_page * (pagination.page - 1) + props.row.index + 1}
        </span>
      )
    },
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Retailer' className='min-w-125px' />
    ),
    accessor: 'retailer',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Cashier' className='min-w-125px' />
    ),
    accessor: 'cashier',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Bets' className='min-w-125px' />
    ),
    accessor: 'bets',
  },
  // {
  //   Header: (props) => (
  //     <RecordCustomHeader tableProps={props} title='Bet Count' className='min-w-125px' />
  //   ),
  //   accessor: 'bet_count',
  // },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Slip Count' className='min-w-125px' />
    ),
    accessor: 'slip_count',
  },
  // {
  //   Header: (props) => (
  //     <RecordCustomHeader tableProps={props} title='Bet Winnings' className='min-w-125px' />
  //   ),
  //   accessor: 'bet_winning',
  // },
  // {
  //   Header: (props) => (
  //     <RecordCustomHeader tableProps={props} title='Bet Winning Count' className='min-w-125px' />
  //   ),
  //   accessor: 'bet_winning_count',
  // },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Cancellations' className='min-w-125px' />
    ),
    accessor: 'cancellations',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Cancellation Count' className='min-w-125px' />
    ),
    accessor: 'cancellation_count',
  },
  // {
  //   Header: (props) => (
  //     <RecordCustomHeader
  //       tableProps={props}
  //       title='Unclaimed Bet Winnings'
  //       className='min-w-125px'
  //     />
  //   ),
  //   accessor: 'unclaimed_bet_winning',
  // },
  // {
  //   Header: (props) => (
  //     <RecordCustomHeader
  //       tableProps={props}
  //       title='Unclaimed Bet Winning Count'
  //       className='min-w-125px'
  //     />
  //   ),
  //   accessor: 'unclaimed_bet_winning_count',
  // },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Redeemed' className='min-w-125px' />
    ),
    accessor: 'redeemed',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Redeemed Count' className='min-w-125px' />
    ),
    accessor: 'redeemed_count',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Jackpot' className='min-w-125px' />
    ),
    accessor: 'jackpot',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='GGR' className='min-w-125px' />
    ),
    accessor: 'ggr',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='NGR' className='min-w-125px' />
    ),
    accessor: 'ngr',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Net Cash' className='min-w-125px' />
    ),
    accessor: 'net_cash',
  },
]

const uncliamedRecordsColumns: ReadonlyArray<Column<Records>> = [
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='No.' className='text-left min-w-60px' />
    ),
    id: 'number',
    Cell: ({...props}) => {
      const pagination = useQueryResponsePagination()
      return (
        <span id={props.row.index} className='text-right'>
          {props.row.index === props.rows.length - 1
            ? ''
            : pagination.items_per_page * (pagination.page - 1) + props.row.index + 1}
        </span>
      )
    },
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Retailer' className='min-w-125px' />
    ),
    accessor: 'retailer_name',
    Cell: ({...props}) => {
      console.log(props.data[props.row.index])
      return <span>{props.data[props.row.index].retailer_name}</span>
    },
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Cashier' className='min-w-125px' />
    ),
    accessor: 'user_name',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Keno' className='min-w-125px' />
    ),
    accessor: 'keno_amount',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Spin And Win' className='min-w-125px' />
    ),
    accessor: 'spin_amount',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Runner' className='min-w-125px' />
    ),
    accessor: 'runner_amount',
  },
  {
    Header: (props) => (
      <RecordCustomHeader tableProps={props} title='Total' className='min-w-125px' />
    ),
    accessor: 'total',
  },
]

export {netrevenuRecordsColumns, uncliamedRecordsColumns}
