import {useEffect} from 'react'

import {KTCard} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {ReportsForm} from './components/ReportsForm'
import {ListViewProvider} from './components/core/ListViewProvider'
import {QueryRequestProvider} from './components/core/QueryRequestProvider'
import {QueryResponseProvider} from './components/core/QueryResponseProvider'
// import {NetRevenuRecordsTable} from './components/table/NetRevenuReportsTable'
// import {UncliamedRecordsTable} from './components/table/UncliamedReportsTable'
import {ReportsTabs} from './components/ReportsTabs'
import {useState} from 'react'
import {TabKey, TabsProps} from './types'
import {ReportTable} from './components/table/ReportTable'

const ReportsPage: React.FC<TabsProps> = ({activeTab, onTabClick}) => {
  return <ReportsTabs activeTab={activeTab} onTabClick={onTabClick} />
}

const ReportsPageWrapper = () => {
  const [activeTab, setActiveTab] = useState<TabKey>('Net Revenue')

  useEffect(() => {
    // Bootstrap provides "fade" and "show" classes to handle fading in/out
    const reportsForm = document.querySelector('.reports-form')

    if (reportsForm) {
      reportsForm.classList.add('fade')
      reportsForm.classList.remove('show')

      // Timeout to add "show" class after the fade-in animation completes
      const timeoutId = setTimeout(() => {
        reportsForm.classList.add('show')
      }, 200) // Adjust the delay to match the transition duration

      return () => clearTimeout(timeoutId)
    }
  }, [activeTab])

  const handleTabClick = (tabKey: TabKey) => {
    setActiveTab(tabKey)
  }

  return (
    <>
      <PageTitle>Reports</PageTitle>
      <ReportsPage activeTab={activeTab} onTabClick={handleTabClick} />
      <QueryRequestProvider>
        <QueryResponseProvider activeReport={activeTab}>
          <ListViewProvider>
            <KTCard>
              <ReportsForm activeReport={activeTab} />
              <hr />
              <ReportTable activeReport={activeTab} />
              {/* {activeTab === 'Net Revenue' ? <NetRevenuRecordsTable /> : <UncliamedRecordsTable />} */}
            </KTCard>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {ReportsPageWrapper}
