/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {QueryKey, useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {getSlipReport} from './_requests'
import {SlipRecord} from './_models'
import {BodyFilterContext, useBodyFilter, useQueryRequest} from './QueryRequestProvider'

let queryName: QueryKey

const QueryResponseContext = createResponseContext<SlipRecord>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {filter, updateFilter} = useBodyFilter()
  const filterString = useMemo(() => JSON.stringify(filter), [filter])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  queryName = `records-list-${filterString}-${query}`

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    queryName,
    () => {
      return getSlipReport(filter, query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <BodyFilterContext.Provider value={{filter, updateFilter}}>
      <QueryResponseContext.Provider
        value={{isLoading: isFetching, refetch, response: response, query}}
      >
        {children}
      </QueryResponseContext.Provider>
    </BodyFilterContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}
const useQueryStakeInfo = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }
  // @ts-ignore
  return response?.stake_info || {}
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryStakeInfo,
  useQueryResponsePagination,
  useQueryResponseLoading,
  queryName,
}
