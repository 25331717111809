import axios, {AxiosResponse} from 'axios'
import {Filter, SlipDetailResponse, SlipRecord} from './_models'

const API_URL = import.meta.env.REACT_APP_API_URL

const getSlipReport = (filter: Filter, query: string) => {
  return axios
    .post(`${API_URL}/reports/slip?${query}`, {...filter})
    .then(
      (response: AxiosResponse<{data: SlipRecord[]; payload: any; stake_info: any}>) => response.data
    )
}

const fetchTicketInfo = async (slipId: string | undefined) => {
  try {
    const response = await axios.get<SlipDetailResponse>(`${API_URL}/reports/slip/${slipId}`)

    if (!response.data) {
      console.error('Empty response data')
      return null
    }

    const receiptData = response.data
    return receiptData
  } catch (error) {
    console.error('Error fetching receipt data:', error)
    throw error
  }
}

const redeemFailedBet = (id: String) => {
  return axios.patch(`${API_URL}/bets/redeem/${id}`).then((res) => res)
}

export {getSlipReport, redeemFailedBet, fetchTicketInfo}
