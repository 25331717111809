// TotalInfo.tsx

import React from 'react'

interface TotalInfoProps {
  totalStake: number
  totalWinnings: number
  jackpot_amount?: number | null
}

const TotalInfo: React.FC<TotalInfoProps> = ({totalStake, totalWinnings, jackpot_amount}) => {
  return (
    <>
      <hr />
      <div className='d-flex justify-content-between pt-2 px-3 border-top-2'>
        <div className='d-flex flex-row gap-10'>
          <div style={{marginBottom: '16px'}}>
            <h5>Total Stake</h5>
            <h4 style={{color: '#3498db'}}>
              {totalStake} <span>ETB</span>
            </h4>
          </div>
          <div>
            <h5>Total Winnings</h5>
            <h4 style={{color: '#3498db'}}>
              {totalWinnings} <span>ETB</span>
            </h4>
          </div>
        </div>
        {jackpot_amount && (
          <div>
            <h4 style={{color: '#3498db'}}>Jackpot Ticket</h4>
            <h5>
              {Number(jackpot_amount).toLocaleString()} <span>ETB</span>
            </h5>
          </div>
        )}
      </div>
    </>
  )
}

export default TotalInfo
