import {ReactNode} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

type CardTemplateProps = {
  title?: string
  imgSrc: string
  children: ReactNode
}

export function CardTemplate(props: CardTemplateProps) {
  const {title, imgSrc, children} = props

  return (
    <div className='col-lg-4 col-md-6 col-sm-12'>
      <div className='card p-8'>
        <div className='card flex flex-row justify-content-between'>
          <div>
            <div className='h3 pb-8'>{title}</div>
            {children}
          </div>
          <div>
            <KTSVG
              path={`/media/icons/duotune/${imgSrc}`}
              className='svg-icon svg-icon-5hx text-primary'
              svgClassName='mh-100px'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
