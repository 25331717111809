import {FC, useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../core/QueryResponseProvider'
import {netrevenuRecordsColumns, uncliamedRecordsColumns} from './columns/_columns'
import {Records} from '../core/_models'
import {RecordsListLoading} from '../loading/RecordsListLoading'
import {RecordsListPagination} from '../pagination/RecordsListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {TabKey} from '../../types'

const ReportTable: FC<{activeReport: TabKey}> = ({activeReport}) => {
  const records = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => records, [records])
  const pagination = useQueryResponsePagination()

  const columns = useMemo(
    () => (activeReport === 'Net Revenue' ? netrevenuRecordsColumns : uncliamedRecordsColumns),
    [activeReport]
  )

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data ?? [],
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_records'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Records>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 1 ? (
              rows.map((row: Row<Records>, i) => {
                prepareRow(row)
                const isLastRow = i === rows.length - 1
                return (
                  <CustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    additionalClass={isLastRow ? 'bg-gray-300 text-dark' : ''}
                  />
                )
              })
            ) : (
              <tr>
                <td colSpan={16}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <RecordsListPagination isLoading={isLoading} pagination={pagination} />
      {isLoading && <RecordsListLoading />}
    </KTCardBody>
  )
}

export {ReportTable}
