import {FC, useEffect, useState} from 'react'
import {getRetailers} from '../../retailers/core/_requests'
import {getCashiers} from './core/_requests'
import {useBodyFilter} from './core/QueryRequestProvider'
import {ExportToExcel} from './Export'
import {Filter} from './core/_models'
import dayjs from 'dayjs'
import {getAgents} from '../../apps/user-management/agent/core/_requests'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {Agent, Retailer, TabKey} from '../types'
import {CashierModel} from '../../retailers/core/_models'
import {getGamesByCompany} from '../../organizations/core/_requests'
import {useAuth} from '../../auth'
import {selectComponentStyle} from '../../../../_metronic/helpers/styles'

const ReportsForm: FC<{activeReport: TabKey}> = ({activeReport}) => {
  const animatedComponents = makeAnimated()
  const {mode} = useThemeMode()
  const selectStyle = selectComponentStyle(mode)
  const {currentUser} = useAuth()
  const now = new Date()
  const [from, setFrom] = useState(formatted_date(dayjs(now).startOf('day').toDate()))
  const [to, setTo] = useState(formatted_date(dayjs(now).endOf('day').toDate()))

  const [allCashiers, setAllCashiers] = useState<CashierModel[]>([])
  const [cashiers, setCashiers] = useState<CashierModel[]>([])
  const [selectedCashiers, setSelectedCashiers] = useState<CashierModel[]>([])

  const [allRetailers, setAllRetailers] = useState<Retailer[]>([])
  const [retailers, setRetailers] = useState<Retailer[]>([])
  const [selectedRetailers, setSelectedRetailers] = useState<Retailer[]>([])

  const [, setAllAgents] = useState<Agent[]>([])
  const [agents, setAgents] = useState<Agent[]>([])
  const [selectedAgents, setSelectedAgents] = useState<Agent[]>([])

  const [game, setGame] = useState<string[]>([])
  const [selectedGames, setSelectedGames] = useState<string[]>([])

  const [filter, setFilter] = useState<Filter>({})

  const {updateFilter} = useBodyFilter()

  const updateCashiers = (e: any) => {
    setSelectedCashiers(e)
    const cashier_ids = e.map((c: any) => c.id)
    setFilter({...filter, cashier_id: cashier_ids.length > 0 ? cashier_ids : undefined})
  }

  const updateGame = (e: any) => {
    setSelectedGames(e)
    // const game_type = e.map((g: any) => g.value)
    setFilter({...filter, game_type: e.length > 0 ? e.map((g: any) => g.value) : undefined})
  }

  const updateRetailer = (e: any) => {
    setSelectedRetailers(e)
    setSelectedCashiers([])
    if (e.length > 0) {
      const cashiers = allCashiers.filter((cashier: CashierModel, i) => {
        const retails = e?.map((retailer: Retailer) => {
          const retailsIds = retailer.Cashier.map((cashier: CashierModel) => {
            return cashier.id
          })

          return retailsIds
        })
        return retails.flat().includes(cashier.id) ? cashier : 0
      })
      setCashiers(cashiers)
    } else setCashiers(allCashiers)
    const retail_ids = e.map((r: any) => r.id)
    setFilter({...filter, retailer_id: retail_ids.length > 0 ? retail_ids : undefined})
  }

  const updateAgent = (e: any) => {
    setSelectedAgents(e)
    setSelectedRetailers([])
    setSelectedCashiers([])
    if (e.length > 0) {
      const retailers = allRetailers.filter((retailer: Retailer, i) => {
        const retails = e?.map((agent: any) => {
          const retailsIds = agent.retails.map((retail: Retailer) => {
            return retail.id
          })

          return retailsIds
        })
        return retails.flat().includes(retailer.id) ? retailer : 0
      })

      setRetailers(retailers)
    } else {
      setRetailers(allRetailers)
      setCashiers(allCashiers)
    }
    const agent_ids = e.map((a: any) => a.id)
    setFilter({...filter, agent_id: agent_ids.length > 0 ? agent_ids : undefined})
  }

  const formReset = () => {
    const from = formatted_date(dayjs().startOf('day').toDate()),
      to = formatted_date(dayjs().endOf('day').toDate())
    setFrom(from)
    setTo(to)
    setSelectedAgents([])
    setSelectedRetailers([])
    setSelectedCashiers([])
    setSelectedGames([])
    setRetailers(allRetailers)
    setCashiers(allCashiers)
    const dateSelector = document.getElementById('dates') as HTMLFormElement
    dateSelector.value = '1'
    setFilter({from, to})
    updateFilter({from, to})
  }

  // Clear form when clear button is clicked
  const clearForm = () => {
    formReset()
  }

  // Reset selected agents, retailers, cashiers, games when switching between reports
  useEffect(() => {
    formReset()
  }, [activeReport])

  useEffect(() => {
    // fetch agents
    getAgents('').then((res: any) => {
      setAgents(
        res
          .filter((item: Agent) => {
            if (currentUser?.userType === 'Agent' && item.id === currentUser?.id) {
              return true
            } else if (currentUser?.userType !== 'Agent') return true
          })
          .map((item: Agent) => ({
            label: item.name,
            value: item.id,
            id: item.id,
            retails: item.Retails,
          }))
      )
      setAllAgents(
        res.map((item: Agent) => ({
          label: item.name,
          value: item.id,
          id: item.id,
          retails: item.Retails,
        }))
      )
    })

    // fetch retailers
    getRetailers('').then((res: any) => {
      setRetailers(
        res.map((item: Retailer) => ({
          label: item.name,
          value: item.id,
          id: item.id,
          Cashier: item.Cashier,
        }))
      )
      setAllRetailers(
        res.map((item: Retailer) => ({
          label: item.name,
          value: item.id,
          id: item.id,
          Cashier: item.Cashier,
        }))
      )
    })

    // fetch cashiers
    getCashiers().then((res: any) => {
      setCashiers(
        res.map((item: CashierModel) => ({label: item.User?.username, value: item.id, id: item.id}))
      )
      setAllCashiers(
        res.map((item: CashierModel) => ({label: item.User?.username, value: item.id, id: item.id}))
      )
    })

    getGamesByCompany(currentUser?.Company?.id).then((res: any) => {
      setGame(
        res?.data?.assigned_games?.map((item: any) => ({
          label: item?.game_type.replaceAll('_', ' ').toLocaleLowerCase(),
          value: item?.game_type,
        }))
      )
    })
  }, [])

  function formatted_date(date: Date) {
    return date.toLocaleString('sv').slice(0, 16).replace(' ', 'T')
  }
  const handleDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = e.target
    const now = new Date()
    const this_month_start = dayjs(now).startOf('month').startOf('day').toDate(),
      this_week_start = dayjs(now)
        .subtract(now.getDay() === 0 ? 1 : 0, 'week') // Set past week on sunday
        .startOf('week')
        .add(1, 'day')
        .startOf('day')
        .toDate(),
      yesterday = dayjs(now).subtract(1, 'day').startOf('day').toDate(),
      today_start = dayjs(now).startOf('day').toDate(),
      today_end = dayjs(now).endOf('day').toDate(),
      last_week_start = dayjs(now)
        .subtract(now.getDay() === 0 ? 2 : 1, 'week') // Set past week on sunday
        .startOf('week')
        .add(1, 'day')
        .toDate(),
      last_week_end = dayjs(now)
        .subtract(now.getDay() === 0 ? 2 : 1, 'week') // Set past week on sunday
        .endOf('week')
        .add(1, 'day')
        .toDate(),
      last_month_start = dayjs(now).subtract(1, 'month').startOf('month').toDate(),
      last_month_end = dayjs(now).subtract(1, 'month').endOf('month').toDate()

    setTo(formatted_date(today_end))
    switch (value) {
      case '1': // Today
        setFrom(formatted_date(today_start))
        break
      case '2': // Yesterday
        setFrom(formatted_date(yesterday))
        setTo(formatted_date(today_start))
        break
      case '3': // This Week
        setFrom(formatted_date(this_week_start))
        break
      case '4': // Last Week
        setFrom(formatted_date(last_week_start))
        setTo(formatted_date(last_week_end))
        break
      case '5': // This Month
        setFrom(formatted_date(this_month_start))
        break
      case '6': // Last Month
        setFrom(formatted_date(last_month_start))
        setTo(formatted_date(last_month_end))
        break
      default:
        setFrom('')
        setTo('')
        break
    }
  }

  useEffect(() => {
    setFilter({
      ...filter,
      from: from,
      to: to,
    })
  }, [from, to, filter])

  return (
    <>
      <div className='card-body reports-form fade'>
        <form className='form form-label-right d-flex gap-4 flex-column'>
          <div className='form-group col justify-content-between'>
            <div className='col-12 position-relative'>
              <div className='d-flex flex-column gap-2'>
                <label>Agents:</label>

                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  onChange={(e: any) => {
                    updateAgent(e)
                  }}
                  options={agents}
                  value={selectedAgents}
                  styles={selectStyle.selectStyle}
                  theme={selectStyle.themes}
                />
              </div>
            </div>

            <div className='col-12 position-relative'>
              <div className='d-flex flex-column gap-2 width-100%'>
                <label>Retailers:</label>
                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  onChange={(e: any) => {
                    console.log(e)
                    updateRetailer(e)
                  }}
                  options={retailers}
                  value={selectedRetailers}
                  styles={selectStyle.selectStyle}
                  theme={selectStyle.themes}
                />
              </div>
            </div>

            <div className='col-12 position-relative'>
              <div className='d-flex flex-column gap-2'>
                <label>Cashier:</label>
                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  onChange={(e: any) => {
                    updateCashiers(e)
                  }}
                  options={cashiers}
                  value={selectedCashiers}
                  styles={selectStyle.selectStyle}
                  theme={selectStyle.themes}
                />
              </div>
            </div>
          </div>
          <div className='form-group row justify-content-between'>
            <div className='col-3'>
              <label>Dates:</label>
              <select
                className='form-control'
                onChange={handleDateRangeChange}
                id='dates'
                defaultValue={'1'}
              >
                <option value=''>All</option>
                <option value='1'>Today</option>
                <option value='2'>Yesterday</option>
                <option value='3'>This Week</option>
                <option value='4'>Last Week</option>
                <option value='5'>This Month</option>
                <option value='6'>Last Month</option>
              </select>
            </div>
            <div className='col-3'>
              <label>From:</label>
              <input
                id='from'
                name='from'
                type='datetime-local'
                className='form-control'
                value={from}
                onChange={(e) => {
                  setFrom(e.target.value)
                }}
              />
            </div>
            <div className='col-3'>
              <label>To:</label>
              <input
                id='to'
                name='to'
                type='datetime-local'
                className='form-control'
                value={to}
                onChange={(e) => {
                  setTo(e.target.value)
                }}
              />
            </div>
            <div className='col-3'>
              <div className='d-flex flex-column gap-2'>
                <label>Game type:</label>

                <Select
                  // closeMenuOnSelect={false}
                  components={animatedComponents}
                  isDisabled={activeReport === 'Uncliamed'}
                  isMulti
                  onChange={(e: any) => {
                    updateGame(e)
                  }}
                  options={game}
                  value={selectedGames}
                  styles={selectStyle.selectStyle}
                  theme={selectStyle.themes}
                />
              </div>
              {/* <input
                id='game_type'
                name='game_type'
                type='text'
                className='form-control'
                value={gameType}
                onChange={(e) => {
                  setGameType(e.target.value)
                }}
              /> */}
            </div>
          </div>
          <br />
        </form>
        <div className='row'>
          <div className='col-6'>
            <button
              type='button'
              className='btn btn-sm btn-primary m-2'
              onClick={() => updateFilter(filter)}
            >
              Submit
            </button>
            <button type='reset' className='btn btn-sm btn-light-danger m-3' onClick={clearForm}>
              Clear
            </button>
          </div>
          <div className='col-6 d-flex flex-col justify-content-end align-items-center'>
            <ExportToExcel filter={filter} />
          </div>
        </div>
      </div>
    </>
  )
}

export {ReportsForm}
