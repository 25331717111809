import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'
import {ReportsPageWrapper} from '../modules/reports/ReportsPage'
import {SlipManagementPages} from '../modules/slip-management/components/slipinfo/SlipManagementPages'
import {SubscriptionWrapper} from '../pages/Subscription_Management/Subscription'
import {TrackingBillsWrapper} from '../modules/tracking-bills/TrackingBills'

const PrivateRoutes = () => {
  const OrganizationsPage = lazy(() => import('../modules/organizations/OrganizationsPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const RetailersPage = lazy(() => import('../modules/retailers/RetailersPage'))
  const TrackingBillsPage = lazy(() => import('../modules/tracking-bills/TrackingBillsPage'))
  const BalanceAdjustmentPage = lazy(
    () => import('../modules/balance-adjustment/BalanceAdjustmentPage')
  )
  const SystemSettingsWrapper = lazy(
    () => import('../modules/system-settings/SystemSettingsWrapper')
  )
  const JackpotsPage = lazy(() => import('../modules/jackpot/JackpotsPage'))
  const OddsPage = lazy(() => import('../modules/odds/OddsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='finance' element={<SubscriptionWrapper />} />

        <Route path='tracking-bills' element={<TrackingBillsWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='tracking-bills/*'
          element={
            <SuspensedView>
              <TrackingBillsPage />
            </SuspensedView>
          }
        />
        <Route
          path='organization/*'
          element={
            <SuspensedView>
              <OrganizationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsPageWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='apps/retailers/*'
          element={
            <SuspensedView>
              <RetailersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/settings/*'
          element={
            <SuspensedView>
              <SystemSettingsWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='apps/balance-adjustment/*'
          element={
            <SuspensedView>
              <BalanceAdjustmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='slip-management/*'
          element={
            <SuspensedView>
              <SlipManagementPages />
            </SuspensedView>
          }
        />
        <Route
          path='jackpot/*'
          element={
            <SuspensedView>
              <JackpotsPage />
            </SuspensedView>
          }
        />
        <Route
          path='odds/*'
          element={
            <SuspensedView>
              <OddsPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
