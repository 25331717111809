import React, {createContext, useContext, useState} from 'react'
import {socket} from '../../../helpers/socket'
import {UserModel} from '../../../../app/modules/auth'
import {Notification} from './_models'

type NotificationContextType = {
  isNotificationPanelOpen: boolean
  setIsNotificationPanelOpen: (isOpen: boolean) => void
  notifications: Notification[]
  setNotifications: (notifications: Notification[]) => void
}

const defaultNotificationState: NotificationContextType = {
  isNotificationPanelOpen: false,
  setIsNotificationPanelOpen: () => {},
  notifications: [],
  setNotifications: () => {},
}

const NotificationContext = createContext<NotificationContextType>({
  isNotificationPanelOpen: false,
  setIsNotificationPanelOpen: () => {},
  notifications: [],
  setNotifications: () => {},
})

const useNotification = () => useContext(NotificationContext)

const NotificationProvider = ({children, user}: {children: React.ReactNode; user?: UserModel}) => {
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState<boolean>(
    defaultNotificationState.isNotificationPanelOpen
  )
  const [notifications, setNotifications] = useState<Notification[]>(
    defaultNotificationState.notifications
  )

  // Subscribe for user specific notifications
  socket.on(`notification_${user?.id}`, (notification: Notification) => {
    setNotifications([...notifications, notification])
  })

  return (
    <NotificationContext.Provider
      value={{
        isNotificationPanelOpen,
        setIsNotificationPanelOpen,
        notifications,
        setNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export {NotificationProvider, useNotification}
