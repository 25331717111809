import {subDays, subWeeks, subMonths} from 'date-fns'
import {RangeType} from '../../../modules/organizations/types'

const calculateTargetDate = (range: RangeType): Date => {
  const currentDate = new Date()
  console.log(subWeeks(currentDate, 1))

  switch (range) {
    case 'TODAY':
      return currentDate
    case 'YESTERDAY':
      return subDays(currentDate, 1)
    case 'THIS_WEEK':
      return subWeeks(currentDate, 1)
    case 'PREVIOUS_WEEK':
      return subWeeks(currentDate, 1)
    case 'THIS_MONTH':
      return currentDate
    case 'PREVIOUS_MONTH':
      return subMonths(currentDate, 1)
    case 'LAST_7_DAYS':
      return subWeeks(currentDate, 1)
    case 'LAST_30_DAYS':
      return subMonths(currentDate, 1)
    default:
      return currentDate
  }
}

export {calculateTargetDate}
