/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../core/QueryResponseProvider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useEffect, useState} from 'react'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const RecordsListPagination = () => {
  const [paginationLinks, setPaginationLinks] = useState<
    Array<{label: string; active: boolean; url: string | null; page: number | null}>
  >([])
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, items_per_page: pagination.items_per_page || 10})
  }

  useEffect(() => {
    const links =
      pagination.links?.filter((link) => {
        if (link.page && pagination.page <= 3) {
          return link.page <= 4 || link.page === pagination.links?.length
        } else if (
          link.page &&
          pagination.links?.length &&
          pagination.page >= pagination.links.length - 2
        ) {
          return link.page >= pagination.links?.length - 3 || link.page === 1
        } else {
          return link.page
            ? link.page === 1 ||
                link.page === pagination.page - 1 ||
                link.page === pagination.page ||
                link.page === pagination.page + 1 ||
                link.page === pagination.links?.length
            : null
        }
      }) ?? []

    setPaginationLinks(links)
  }, [pagination])

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-7 offset-md-5 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            <a
              className={clsx('page-link', {
                'page-text': true,
                'me-5': true,
                disabled: pagination.page < 2,
              })}
              onClick={() => updatePage(pagination.page - 1)}
              style={{cursor: 'pointer'}}
            >
              <i className='ki-duotone ki-left'></i> Previous
            </a>
            {paginationLinks.map((link, i) => (
              <span key={i}>
                <li
                  key={link.label}
                  className={clsx('page-item d-flex', {
                    active: pagination.page === link.page,
                    disabled: isLoading,
                    previous: link.label === 'Previous',
                    next: link.label === 'Next',
                  })}
                >
                  {pagination.links?.length &&
                    pagination.links.length > 4 &&
                    pagination.page < pagination.links.length - 2 &&
                    link.page === pagination.links.length && (
                      <div className={'page-link'} key={link.label + 'prev'}>
                        ..
                      </div>
                    )}
                  <a
                    className={clsx('page-link', {
                      'page-text': link.label === 'Previous' || link.label === 'Next',
                      'me-5': link.label === 'Previous',
                    })}
                    onClick={() => updatePage(link.page)}
                    style={{cursor: 'pointer'}}
                  >
                    {mappedLabel(link.label)}
                  </a>
                  {pagination.links?.length &&
                    pagination.links.length > 4 &&
                    pagination.page > 3 &&
                    link.page === 1 && (
                      <div className={'page-link'} key={link.label + 'next'}>
                        ..
                      </div>
                    )}
                </li>
              </span>
            ))}
            <a
              className={clsx('page-link', {
                'page-text': true,
                'me-5': true,
                disabled: pagination.page >= (pagination.links?.length ?? -1),
              })}
              onClick={() => updatePage(pagination.page + 1)}
              style={{cursor: 'pointer'}}
            >
              Next <i className='ki-duotone ki-right'></i>
            </a>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {RecordsListPagination}
