import axios, {AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {ID, QueryState, Response, SearchResponse} from '../../../../_metronic/helpers'
import {Retailer, RetailersQueryResponse, SearchQueryResponse} from './_models'

const API_URL = import.meta.env.REACT_APP_API_URL
const RETAILERS_URL = `${API_URL}/retailers`
const SEARCH_URL = `${API_URL}/search`
const model = 'Retailer'

const searchRetailers = (query: Partial<QueryState>): Promise<SearchQueryResponse> => {
  return axios
    .post(`${SEARCH_URL}`, {indexName: 'retailers', ...query})
    .then((d: AxiosResponse<SearchResponse<Array<Retailer>>>) => {
      return d.data
    })
}

const getRetailers = (query: string): Promise<RetailersQueryResponse> => {
  return axios.get(`${RETAILERS_URL}?${query}`).then((d: AxiosResponse<RetailersQueryResponse>) => {
    // console.log(d.data)

    return d.data
  })
}

const getRetailerById = (id: ID) => {
  return axios.get(`${RETAILERS_URL}/${id}`).then((d) => d.data)
}

const createRetailer = (retailer: Retailer): Promise<Retailer | undefined> => {
  return axios
    .post(RETAILERS_URL, retailer)
    .then((response: AxiosResponse<Response<Retailer>>) => {
      if (String(response.status).startsWith('2')) toast.success(`${model} created successfully`)
      else toast.error(`Creating ${model} failed`)
      return response.data
    })
    .then((response: Response<Retailer>) => response.data)
    .catch((error) => {
      if (error.response) {
        toast.error(error.response.data.message)
      } else {
        toast.error(`Creating ${model} failed`)
      }
      throw error
    })
}

const updateRetailer = (retailer: Retailer): Promise<Retailer | undefined> => {
  return axios
    .patch(`${RETAILERS_URL}/${retailer.id}`, retailer)
    .then((response: AxiosResponse<Response<Retailer>>) => {
      if (String(response.status).startsWith('2')) toast.success(`${model} updated successfully`)
      else {
        toast.error(`Update ${model} failed`)
      }
      return response.data
    })
    .then((response: Response<Retailer>) => response.data)
    .catch((error) => {
      if (error.response) {
        toast.error(error.response.data.message)
      } else {
        toast.error(`Creating ${model} failed`)
      }
      throw error
    })
}

const deleteRetailer = (retailerId: ID): Promise<void> => {
  return axios
    .delete(`${RETAILERS_URL}/${retailerId}`)
    .then((res) => {
      if (String(res.status).startsWith('2')) toast.success(`${model} deleted successfully`)
    })
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          `Something went wrong`
      )
      throw error
    })
}

const deleteSelectedRetailers = (retailerIds: Array<ID>): Promise<void> => {
  const requests = retailerIds.map((id) => axios.delete(`${RETAILERS_URL}/${id}`))
  return axios
    .all(requests)
    .then((res) => {
      if (String(res[0].status).startsWith('2')) toast.success(`${model} deleted successfully`)
    })
    .catch((error) => {
      toast.error(
        error.response.data?.['errors']?.[0] ??
          error.response.data.message ??
          `Something went wrong`
      )
      throw error
    })
}

export {
  getRetailerById,
  getRetailers,
  createRetailer,
  updateRetailer,
  deleteRetailer,
  deleteSelectedRetailers,
  searchRetailers,
}
