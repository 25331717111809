import {useEffect} from 'react'
import Alert from 'react-bootstrap/Alert'
import {Outlet, useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import {ThemeModeProvider} from '../partials/layout/theme-mode/ThemeModeProvider'
import {Content} from './components/Content'
import {Footer} from './components/Footer'
import {ScrollTop} from './components/ScrollTop'
import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {PageDataProvider} from './core'
import {useErrorContext} from './core/Error'
import {NotificationProvider} from '../partials/layout/notification/NotificationProvider'
import {useAuth} from '../../app/modules/auth'

const MasterLayout = () => {
  const location = useLocation()
  const {currentUser} = useAuth()
  const {message, values, clearError, retry} = useErrorContext()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <NotificationProvider user={currentUser}>
          <div className='d-flex flex-column flex-root'>
            {message && (
              <Alert
                variant={'danger'}
                onClose={() => clearError()}
                dismissible
                style={{margin: '0.75rem', position: 'absolute', top: '0', right: '0'}}
              >
                {message}&nbsp;&nbsp;
                <a onClick={() => retry()} href='#'>
                  <i className='fa fa-refresh' style={{color: 'red'}}></i>
                </a>
              </Alert>
            )}
            <div className='page d-flex flex-row flex-column-fluid'>
              <AsideDefault />

              <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                <HeaderWrapper />

                <div id='kt_content' className='content pt-0 d-flex flex-column flex-column-fluid'>
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <Footer />
              </div>
            </div>
          </div>
          <ScrollTop />
        </NotificationProvider>
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
