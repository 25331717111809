import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ErrorContextProvider} from '../_metronic/layout/core/Error'
import {ToastContainer} from 'react-toastify'
import {useThemeMode} from '../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'

const App = () => {
  const {mode} = useThemeMode()

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <ErrorContextProvider>
              <ToastContainer
                closeOnClick={false}
                hideProgressBar={true}
                autoClose={800}
                position='top-right'
                theme={mode === 'dark' ? 'dark' : 'light'}
              />
              <Outlet />
              <MasterInit />
            </ErrorContextProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
