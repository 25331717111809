import React, {MouseEvent, useEffect, useState} from 'react'
import {RangeType} from '../types/dashboard.types'
import {
  systemMode,
  useThemeMode,
} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type DaysProps = {
  clicked: (event: MouseEvent<HTMLDivElement>, value: RangeType) => void
  range: string
  activeDay: string
}

type OptionsProps = {
  title: string
  day: RangeType
}

const options: OptionsProps[] = [
  {title: 'Today', day: 'TODAY'},
  {title: 'Yesterday', day: 'YESTERDAY'},
  {title: 'This Week', day: 'THIS_WEEK'},
  {title: 'Previous Week', day: 'PREVIOUS_WEEK'},
  {title: 'This Month', day: 'THIS_MONTH'},
  {title: 'Previous Month', day: 'PREVIOUS_MONTH'},
  {title: 'Last 7 Days', day: 'LAST_7_DAYS'},
  {title: 'Last 30 Days', day: 'LAST_30_DAYS'},
]

export function Days(props: DaysProps) {
  const {mode} = useThemeMode()
  const [optionHidden, setOptionHidden] = useState<boolean>(false)
  const [theme, setTheme] = useState<string | null>('light')

  useEffect(() => {
    const theme = localStorage.getItem('kt_theme_mode_value')
    setTheme(theme)
  }, [mode])

  const calculatedMode = theme === 'system' ? systemMode : mode

  let bgColor = '#fff'
  if (theme === 'dark' || calculatedMode === 'dark') {
    bgColor = '#232334'
  }

  const {clicked, range, activeDay} = props
  const formmatedActiveDay = activeDay
    .toLocaleLowerCase()
    .replaceAll('_', ' ')
    .split(' ')
    .map((t) => `${t[0].toUpperCase()}${t.slice(1)}`)
    .join(' ')

  const handleOptionHidden = () => {
    setOptionHidden(!optionHidden)
  }

  return (
    <ul
      className='dropdown'
      style={{
        position: 'absolute',
        top: '60px',
        right: '35%',
        zIndex: '9',
      }}
    >
      <button
        type='button'
        className={`btn px-8 py-4 d-flex align-items-center justify-content-between gap-4 dropdown-toggle border p-3 mb-5 rounded" ${
          range === activeDay ? 'active' : ''
        }`}
        style={{width: '200px', backgroundColor: bgColor}}
        data-bs-toggle='dropdown'
        aria-expanded='false'
        data-bs-auto-close='outside'
        onClick={handleOptionHidden}
      >
        {formmatedActiveDay}
      </button>
      <ul
        className={`p-4 shadow rounded mt-2 ${
          optionHidden ? 'visible opacity-100' : 'invisible opacity-0'
        }`}
        style={{
          width: '200px',
          backgroundColor: bgColor,
        }}
      >
        {options.map(({day, title}) => (
          <li key={title} style={{listStyle: 'none'}}>
            <div
              className={` btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
                range === day ? 'active' : ''
              }`}
              id='kt_charts_widget_2_year_btn'
              onClick={(event) => {
                clicked(event, day)
                setOptionHidden(false)
              }}
            >
              {title}
            </div>
          </li>
        ))}
      </ul>
    </ul>
  )
}
