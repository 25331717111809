import React from 'react'

import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {TrackingBiilsModel} from '../core/_models'
import {formatDate} from '../../../../_metronic/helpers/formatDate'

type TableProps = {
  data: TrackingBiilsModel
  onDetailModalOpen: () => void
  // write me a type that recieve a number to be used to slice number of the data to be display in a table
  collected_bill_per_page: number
}

function Table(props: TableProps) {
  const {
    data: {billsCollectedTableData},
    onDetailModalOpen,
    collected_bill_per_page,
  } = props
  const heading = ['BILLED FROM', 'AMOUNT', 'COLLECTED BY', 'DATE CLAIMED', 'STATUS', 'ACTIONS']

  const formattedCollectedBills =
    billsCollectedTableData && billsCollectedTableData.slice(0, collected_bill_per_page)

  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th>
                  <input
                    className='form-check-input bg-primary opacity-10 border p-4'
                    type='checkbox'
                    id='checkboxNoLabel'
                    value=''
                    aria-label='...'
                  />
                </th>
                {heading.map((head, i) => (
                  <th className='text-start min-w-50px' key={i}>
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold border-bottom border-dark border-opacity-10'>
              {formattedCollectedBills?.map(
                ({amount, bills_from, claimed_date, collected_by, status}, i) => (
                  <tr key={i} className='border-bottom border-dark border-opacity-10'>
                    <td>
                      <input
                        className='form-check-input bg-primary opacity-10 border p-4'
                        type='checkbox'
                        id='checkboxNoLabel'
                        value=''
                        aria-label='...'
                      />
                    </td>
                    <td className='text-start min-w-50px text-dark'>{bills_from}</td>
                    <td className='text-start min-w-50px text-dark'>{amount}</td>
                    <td className='text-start min-w-50px text-dark'>{collected_by}</td>
                    <td className='text-start min-w-50px text-dark'>
                      <span>{formatDate(claimed_date)}</span>
                    </td>
                    <td className='text-start min-w-50px text-success'>{status}</td>
                    <td className='text-start min-w-50px d-flex justify-content-end'>
                      <button className='btn btn-primary' onClick={onDetailModalOpen}>
                        See more
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default Table
