import {BillsCollected} from '../core/_models'
import {
  useThemeMode,
  systemMode,
} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type CompanyFilterMenuProps = {
  data: BillsCollected[] | undefined
  current_company: string
  clicked: (company: string) => void
}

const Theme = {
  light: '#fff',
  dark: '#232334',
  system: systemMode === 'light' ? '#fff' : '#232334',
}

const CompanyFilterByName = (props: CompanyFilterMenuProps) => {
  const {data, current_company, clicked} = props
  const {mode} = useThemeMode()

  return (
    <div className='dropdown'>
      <button
        className={`btn dropdown-toggle shadow-sm px-6 py-3 rounded d-flex gap-3 align-items-center text-body-secondary fs-5 fw-bold`}
        type='button'
        style={{backgroundColor: Theme[mode]}}
        data-bs-toggle='dropdown'
        aria-expanded='false'
        data-bs-auto-close='outside'
      >
        {current_company}
      </button>
      <ul className='dropdown-menu dropdown-menu-white w-100 mt-2'>
        {data &&
          data.map(({bills_from}, i) => (
            <li key={i}>
              <div
                className={`dropdown-item fw-bold text-body-secondary ${
                  current_company === bills_from ? 'active' : ''
                }`}
                role='button'
                onClick={() => clicked(bills_from)}
              >
                {bills_from}
              </div>
            </li>
          ))}
        {current_company !== 'All Companies' ? (
          <li>
            <div
              className={`dropdown-item fw-bold text-body-secondary `}
              role='button'
              onClick={() => clicked('All Companies')}
            >
              All Companies
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  )
}

export default CompanyFilterByName
