import {useState, useEffect} from 'react'
import {useBodyFilter} from './core/QueryRequestProvider'
import {Filter} from './core/_models'
import {getRetailers} from '../../retailers/core/_requests'
import dayjs from 'dayjs'
import {Select as CustomSelect, SelectOption} from './slipinfo/Select'
import {getGamesByCompany} from '../../organizations/core/_requests'
import {useAuth} from '../../auth'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {selectComponentStyle} from '../../../../_metronic/helpers/styles'
import {AssignedGames} from '../../organizations/core/_models'

type Retailer = {
  id: number
  label?: string
  value?: number
  agent_id?: number
}

const options = [
  {label: 'Failed', value: 'FAILED', background: '#FFA800'},
  {label: 'Redeemed', value: 'REDEEMED', background: '#3699FF'},
  {label: 'Placed', value: 'PLACED', background: '#FFE600'},
  {label: 'Unclaimed', value: 'UNCLAIMED', background: '#898989'},
  {label: 'Cancelled', value: 'CANCELLED', background: '#F00'},
]

const FailedReportsForm = () => {
  const animatedComponents = makeAnimated()
  const {mode} = useThemeMode()
  const selectStyle = selectComponentStyle(mode)
  const [status, setStatus] = useState<SelectOption[]>([])
  const [retailers, setRetailers] = useState<Retailer[]>([])
  const [selectedRetailers, setSelectedRetailers] = useState<Retailer[]>([])
  const [from, setFrom] = useState(formatted_date(dayjs().startOf('day').toDate()))
  const [to, setTo] = useState(formatted_date(dayjs().endOf('day').toDate()))
  const [filter, setFilter] = useState<Filter>({
    from: formatted_date(dayjs().startOf('day').toDate()),
    to: formatted_date(dayjs().endOf('day').toDate()),
  })
  const {updateFilter} = useBodyFilter()
  const [games, setGames] = useState<SelectOption[]>([])
  const [selectedGames, setSelectedGames] = useState<SelectOption[]>([])
  const {currentUser} = useAuth()

  const updateGame = (e: any) => {
    setSelectedGames(e)
    setFilter({...filter, game_type: e.length > 0 ? e.map((g: any) => g.value) : undefined})
  }

  useEffect(() => {
    if (status.length > 0) {
      setFilter({...filter, status: status.map((item) => `${item.value}`)})
    } else {
      setFilter({...filter, status: undefined})
    }
  }, [status])

  const updateFilterState = () => {
    updateFilter({...filter})
  }

  const clearForm = () => {
    setFrom(formatted_date(dayjs().startOf('day').toDate()))
    setTo(formatted_date(dayjs().endOf('day').toDate()))
    setStatus([])
    setSelectedGames([])
    setSelectedRetailers([])
    setFilter({
      from: formatted_date(dayjs().startOf('day').toDate()),
      to: formatted_date(dayjs().endOf('day').toDate()),
    })
    for (let id of ['retailer_id']) {
      const item = document.getElementById(id) as HTMLFormElement
      if (item) item.value = ''
    }
    const dateSelector = document.getElementById('dates') as HTMLFormElement
    dateSelector.value = '1'
    updateFilter({...filter})
  }

  const updateRetailer = (e: any) => {
    setSelectedRetailers(e)
    const retail_ids = e.map((r: any) => r.id)
    setFilter({...filter, retailer_id: retail_ids.length > 0 ? retail_ids : undefined})
  }

  // fetch retailers
  useEffect(() => {
    getRetailers('').then((res: any) => {
      setRetailers(
        res
          ? res.map((item: any) => ({
              id: item.id!,
              label: item.name,
              value: item.id,
              agent_id: item.agent_id,
            }))
          : []
      )
    })

    getGamesByCompany(currentUser?.Company?.id).then((res: any) => {
      const gameOpt = res.data.assigned_games.filter(
        (game: AssignedGames) => game.game_type !== 'BINGO'
      )
      setGames(
        gameOpt.map((item: AssignedGames) => {
          return {
            label: item?.game_type.replaceAll('_', ' ').toLocaleLowerCase(),
            value: item?.game_type,
          }
        })
      )
    })
  }, [])

  function formatted_date(date: Date) {
    return date.toLocaleString('sv').slice(0, 16).replace(' ', 'T')
  }

  const handleDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = e.target
    const now = new Date()
    const this_month = dayjs(now).startOf('month').startOf('day').toDate(),
      this_week = dayjs(now).startOf('week').add(1, 'day').startOf('day').toDate(),
      yesterday = dayjs(now).subtract(1, 'day').startOf('day').toDate(),
      today = dayjs(now).startOf('day').toDate(),
      today_end = dayjs(now).endOf('day').toDate(),
      last_week = dayjs(now).subtract(1, 'week').startOf('week').add(1, 'day').toDate(),
      end_of_last_week = dayjs(now).startOf('week').add(1, 'day').toDate(),
      last_month = dayjs(now).subtract(1, 'month').startOf('month').toDate(),
      end_of_last_month = dayjs(now).subtract(1, 'month').endOf('month').toDate()

    setTo(formatted_date(today_end))
    switch (value) {
      case '1':
        setFrom(formatted_date(today))
        break
      case '2':
        setFrom(formatted_date(yesterday))
        setTo(formatted_date(today))
        break
      case '3':
        setFrom(formatted_date(this_week))
        break
      case '4': //Last Week
        setFrom(formatted_date(last_week))
        setTo(formatted_date(end_of_last_week))
        break
      case '5':
        setFrom(formatted_date(this_month))
        break
      case '6': //Last Month
        setFrom(formatted_date(last_month))
        setTo(formatted_date(end_of_last_month))
        break
      default:
        setFrom('')
        setTo('')
        break
    }
  }

  useEffect(() => {
    setFilter({
      ...filter,
      from: from,
      to: to,
    })
  }, [from, to, filter])

  return (
    <>
      <div
        className='card-body'
        style={{
          display: 'flex',
          padding: '10px',
          alignItems: 'center',
        }}
      >
        <form
          className='form form-label-right'
          style={{
            padding: '2px',
            display: 'flex',

            marginTop: '-30px',
            //  justifyContent:"space-evenly"
          }}
        >
          <div className='form-group row px-4 py-4'>
            <div className='col-4 my-2 flex flex-column gap-10'>
              <label>Dates:</label>
              <select
                className='form-control'
                onChange={handleDateRangeChange}
                id='dates'
                defaultValue={'1'}
              >
                <option value=''>All</option>
                <option value='1'>Today</option>
                <option value='2'>Yesterday</option>
                <option value='3'>This Week</option>
                <option value='4'>Last Week</option>
                <option value='5'>This Month</option>
                <option value='6'>Last Month</option>
              </select>
            </div>
            <div className='col-4 my-2'>
              <label>From:</label>
              <input
                id='from'
                name='from'
                type='datetime-local'
                className='form-control'
                value={from}
                onChange={(e) => {
                  setFrom(e.target.value)
                }}
              />
            </div>
            <div className='col-4 my-2'>
              <label>To:</label>
              <input
                id='to'
                name='to'
                type='datetime-local'
                className='form-control'
                value={to}
                onChange={(e) => {
                  setTo(e.target.value)
                }}
              />
            </div>
            <div className='col-4 my-2'>
              <label>Retailers:</label>
              <Select
                components={animatedComponents}
                isMulti
                onChange={(e: any) => {
                  updateRetailer(e)
                }}
                options={retailers}
                value={selectedRetailers}
                styles={selectStyle.selectStyle}
                theme={selectStyle.themes}
              />
            </div>
            <div className='col-4 my-2'>
              <label>Game Type</label>
              <Select
                components={animatedComponents}
                isMulti
                onChange={(e: any) => {
                  updateGame(e)
                }}
                options={games}
                value={selectedGames}
                styles={selectStyle.selectStyle}
                theme={selectStyle.themes}
              />
            </div>
            <div className='col-4 my-2'>
              <label>Status</label>
              <CustomSelect multiple options={options} value={status} onChange={setStatus} />
            </div>

            <div className='col-6'>
              <button type='reset' className='btn btn-sm btn-light-primary m-3' onClick={clearForm}>
                Clear
              </button>
              <button
                type='button'
                className='btn btn-sm btn-primary m-2'
                onClick={updateFilterState}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        <div className='row'>
          <div className='col-6 d-flex flex-col justify-content-end align-items-center'></div>
        </div>
      </div>
    </>
  )
}

export {FailedReportsForm}
