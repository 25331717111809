import axios, {AxiosResponse} from 'axios'
import {RangeType} from '../types/dashboard.types'
import {DashboardModel} from './_models'

const API_URL = import.meta.env.REACT_APP_API_URL
const DASHBOARD_URL = `${API_URL}/dashboards`

export const fetchDashboardData = (range: RangeType): Promise<DashboardModel[]> => {
  return axios.get(`${DASHBOARD_URL}?range=${range}`).then((d: AxiosResponse<DashboardModel[]>) => {
    return d.data
  })
}
// const getUsers = (query: string): Promise<UsersQueryResponse> => {
//   return axios.get(`${GET_DASHBOARDS_URL}?${query}`).then((d: AxiosResponse) => d)
// }

// const getUserById = (id: ID): Promise<User | undefined> => {
//   return axios.get(`${DASHBOARD_URL}/${id}`).then((response: AxiosResponse<User>) => response.data)
//   // .then((response: Response<User>) => response.data)
// }

// const createUser = (user: User): Promise<User | undefined> => {
//   return axios
//     .post(DASHBOARD_URL, user)
//     .then((response: AxiosResponse<Response<User>>) => response.data)
//     .then((response: Response<User>) => response.data)
// }

// const updateUser = (user: User): Promise<User | undefined> => {
//   return axios
//     .patch(`${DASHBOARD_URL}/${user.id}`, user)
//     .then((response: AxiosResponse<Response<User>>) => response.data)
//     .then((response: Response<User>) => response.data)
// }

// const deleteUser = (userId: ID): Promise<void> => {
//   return axios.delete(`${DASHBOARD_URL}/${userId}`).then(() => {})
// }

// const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
//   const requests = userIds.map((id) => axios.delete(`${DASHBOARD_URL}/${id}`))
//   return axios.all(requests).then(() => {})
// }

// export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, searchUsers}
