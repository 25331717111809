import React, {MouseEvent} from 'react'
import {RangeType} from '../types/trackingBills.types'
import {
  useThemeMode,
  systemMode,
} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {ThemeModeComponent} from '../../../../_metronic/assets/ts/layout'

type DaysProps = {
  clicked: (event: MouseEvent<HTMLDivElement>, value: RangeType) => void
  range: string
  activeDay: string
}

type OptionsProps = {
  title: string
  day: RangeType
}

const options: OptionsProps[] = [
  {title: 'Today', day: 'TODAY'},
  {title: 'Yesterday', day: 'YESTERDAY'},
  {title: 'This Week', day: 'THIS_WEEK'},
  {title: 'Previous Week', day: 'PREVIOUS_WEEK'},
  {title: 'This Month', day: 'THIS_MONTH'},
  {title: 'Previous Month', day: 'PREVIOUS_MONTH'},
  {title: 'Last 7 Days', day: 'LAST_7_DAYS'},
  {title: 'Last 30 Days', day: 'LAST_30_DAYS'},
]

function Days(props: DaysProps) {
  const {clicked, range, activeDay} = props
  const formmatedActiveDay = activeDay
    .toLocaleLowerCase()
    .replaceAll('_', ' ')
    .split(' ')
    .map((t) => `${t[0].toUpperCase()}${t.slice(1)}`)
    .join(' ')
  const {mode} = useThemeMode()

  const Theme = {
    light: '#fff',
    dark: '#232334',
    system: mode !== 'dark' && mode !== 'system' ? '#232334' : '#fff',
  }
  console.log(mode !== 'dark' && mode !== 'system')
  console.log(systemMode)

  return (
    <ul
      className='dropdown'
      style={{
        position: 'absolute',
        top: '60px',
        right: '40%',
      }}
    >
      <button
        type='button'
        className={`btn px-8 py-4 d-flex align-items-center justify-content-between gap-4 dropdown-toggle border p-3 mb-5  rounded" ${
          range === activeDay ? 'active' : ''
        }`}
        style={{width: '200px', backgroundColor: Theme[mode]}}
        data-bs-toggle='dropdown'
        aria-expanded='false'
        data-bs-auto-close='outside'
      >
        {formmatedActiveDay}
      </button>
      <ul className='dropdown-menu p-4' style={{width: '200px'}}>
        {options.map(({day, title}) => (
          <li key={title}>
            <div
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
                range === day ? 'active' : ''
              }`}
              id='kt_charts_widget_2_year_btn'
              onClick={(event) => clicked(event, day)}
            >
              {title}
            </div>
          </li>
        ))}
      </ul>
    </ul>
  )
}

export default Days
