import {ReactNode} from 'react'
import {SuperAdminDashboardModel} from '../../core/_models'
import {CardTemplate} from './cardTemplate'

type SuperAdminProps = {
  data: SuperAdminDashboardModel[]
  title: string
  imgSrc: string
}

export function SuperAdminCard(props: SuperAdminProps) {
  const {data, title, imgSrc} = props
  const {
    activeShopCount,
    agents,
    net,
    // netTicketCount,
    serviceFee,
    shopCount,
    tenants,
    totalSales,
    // totalTicketCount,
  } = data[0]

  let temp: ReactNode
  //
  switch (title) {
    case 'Net Revenue':
      temp = (
        <>
          <div className='h1'>{net} ETB</div>
          {/* <div className='text-muted h6 pt-2 fw-normal fs-4'>{netTicketCount} Tickets</div> */}
        </>
      )
      break
    case 'Tenants':
      temp = (
        <>
          <div className='h1'>{tenants}</div>
          <div className='text-muted h6 pt-2 fw-normal fs-4'>{agents} Agents</div>
        </>
      )
      break
    case 'Agents':
      temp = (
        <>
          <div className='h1'>{agents}</div>
          <div className='text-muted h6 pt-2 fw-normal fs-4'>{shopCount} Shops</div>
        </>
      )
      break
    case 'Created Shops':
      temp = (
        <>
          <div className='h1'>{shopCount}</div>
          <div className='text-muted h6 pt-2 fw-normal fs-4'>{activeShopCount} Active</div>
        </>
      )
      break
    case 'Service Fee':
      temp = (
        <>
          <div className='h1'>{serviceFee} ETB</div>
          {/* <div className='text-muted h6 pt-2 fw-normal fs-4'>{netTicketCount} Tickets</div> */}
        </>
      )
      break
    default:
      temp = (
        <>
          <div className='h1'>{totalSales} ETB</div>
          {/* <div className='text-muted h6 pt-2 fw-normal fs-4'>{totalTicketCount} Tickets</div> */}
        </>
      )
  }

  return (
    <CardTemplate title={title} imgSrc={imgSrc}>
      {temp}
    </CardTemplate>
  )
}
