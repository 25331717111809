import {BillsCollected} from '../core/_models'
import {formatDate} from '../../../../_metronic/helpers/formatDate'
import {Modal} from '../components/index'

type ClaimPaymentModalDetailProps = {
  isModalOpen: boolean
  onModalOpen: () => void
  // data: BillsCollected | undefined
}

// TEMP
const tempData = {
  company_name: 'Marathon Betting',
  amount_collected: 12000,
  month: 'January 21, 2023',
  collected_by: 'Anteneh Birhanu',
  date_collected: new Date(),
  remark: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam, architecto quia hic tempora dolorem aperiam in ad temporibus quod laudantium fugit ipsum numquam eligendi. Eius commodi aperiam corrupti quibusdam sed.
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam, architecto quia hic tempora dolorem aperiam in ad temporibus quod laudantium fugit ipsum numquam eligendi. Eius commodi aperiam corrupti quibusdam sed.`,
}

const ClaimPaymentModalDetail = (props: ClaimPaymentModalDetailProps) => {
  const {isModalOpen, onModalOpen} = props

  return (
    <Modal isModalOpen={isModalOpen} onCloseModal={onModalOpen} type='DETAIL_MODAL'>
      <div className='d-flex flex-column gap-3'>
        <div className='d-flex flex-column gap-2'>
          <h6>Company</h6>
          <p className='bg-body-secondary py-2 px-4 rounded fs-6'>{tempData.company_name}</p>
        </div>
        <div className='d-flex flex-column gap-2'>
          <h6>Amount Collected</h6>
          <p className='bg-body-secondary py-2 px-4 rounded fs-6'>{tempData.collected_by}</p>
        </div>
        <div className='d-flex justify-content-between gap-3'>
          <div className='w-50 d-flex flex-column gap-2'>
            <h6>Month</h6>
            <p className='bg-body-secondary px-4 py-2 rounded fs-6'>{tempData.month}</p>
          </div>
          <div className='w-50 d-flex flex-column gap-2'>
            <h6>Collected By</h6>
            <p className='bg-body-secondary px-4 py-2 rounded fs-6'>{tempData.collected_by}</p>
          </div>
        </div>
        <div className='d-flex flex-column gap-2'>
          <h6>Date Collected</h6>
          <p className='bg-body-secondary py-2 px-4 rounded fs-6'>
            {formatDate(tempData.date_collected)}
          </p>
        </div>
        <div className='d-flex flex-column gap-2'>
          <h6>Remark</h6>
          <p className='bg-body-secondary py-2 px-4 rounded fs-6'>{tempData.remark}</p>
        </div>
      </div>
    </Modal>
  )
}

export default ClaimPaymentModalDetail
