import React, {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {CustomRow} from '../table/columns/CustomRow'
import {queryName} from '../core/QueryResponseProvider'
import {BetSlipColumns} from './columns/BetSlipColumns'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {useQueryClient} from 'react-query'
import {SlipDetailResponse, SlipRecord} from '../core/_models'
import TotalInfo from '../slipinfo/TotalDisplayBar'

type Props = {
  ticketInfo?: SlipDetailResponse
}

const BetSlip = (props: Props) => {
  const queryClient = useQueryClient()
  const data = useMemo(() => props.ticketInfo?.slipRecords ?? [], [props.ticketInfo])
  const columns = useMemo(() => BetSlipColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data ?? [],
  })

  const removeData = (id: string) => {
    queryClient.invalidateQueries({queryKey: [queryName]})
  }

  return (
    <KTCardBody className='py-4 px-4'>
      <h6 style={{}}>Bet Slip</h6>
      <hr></hr>
      <div className='table-responsive mh-500px'>
        <table
          id='kt_table_records'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<SlipRecord>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<SlipRecord>, i) => {
                prepareRow(row)
                return <CustomRow row={row} remove={removeData} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TotalInfo
        totalStake={props.ticketInfo?.ticket?.[0]?.total_stake ?? 0}
        totalWinnings={props.ticketInfo?.ticket?.[0]?.won_amount ?? 0}
        jackpot_amount={props.ticketInfo?.ticket?.[0]?.jackpot_amount ?? null}
      />
    </KTCardBody>
  )
}

export {BetSlip}
