import {ReactNode} from 'react'

import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'

type TableTemplateProps = {
  heading: string[]
  children: ReactNode
}

export function TableTemplate(props: TableTemplateProps) {
  const {heading, children} = props

  return (
    <KTCard>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {heading.map((head, i) => (
                  <th className='text-start min-w-50px' key={i}>
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>{children}</tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
  )
}
