import React, {useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Filter} from './core/_models'
import {exportReport} from './core/_requests'

export const ExportToExcel = ({filter}: {filter: Filter}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleExportClick = async () => {
    try {
      setIsLoading(true)
      await exportReport(filter)
    } catch (error) {
      console.error('Error exporting report:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <button
      type='button'
      onClick={(e) => handleExportClick()}
      className='btn btn-sm btn-light-primary me-3 float-right'
      disabled={isLoading}
    >
      {isLoading ? (
        <span
          className='spinner-border spinner-border-sm me-2'
          role='status'
          aria-hidden='true'
        ></span>
      ) : (
        <KTIcon iconName='exit-up' className='fs-2' />
      )}
      Export
    </button>
  )
}
