import React from 'react'

import {TabsProps} from '../types'

const ReportsTabs: React.FC<TabsProps> = ({activeTab, onTabClick}) => {
  const tabContainerStyle: React.CSSProperties = {
    display: 'flex',
    gap: 32,
    alignItems: 'center',
    padding: '5px',
    marginBottom: '-18px',
  }

  const tabStyle: React.CSSProperties = {
    cursor: 'pointer',
    color: 'black',
    borderBottom: '2px solid transparent',
    borderRadius: '0px',
    paddingBottom: '20px',
    transition: 'border-bottom 0.3s ease, color 0.3s ease',
  }

  const activeTabStyle: React.CSSProperties = {
    borderBottomColor: '#3498db',
  }

  const inactiveTabStyle: React.CSSProperties = {
    borderBottomColor: 'transparent',
  }

  return (
    <div className='px-4 py-4'>
      <div style={tabContainerStyle}>
        <button
          className={`btn d-flex gap-4 ${activeTab === 'Net Revenue' ? 'active' : ''} text-dark`}
          onClick={() => onTabClick('Net Revenue')}
          style={{
            ...tabStyle,
            ...(activeTab === 'Net Revenue' ? activeTabStyle : inactiveTabStyle),
          }}
        >
          Net Revenue
        </button>
        <button
          className={`btn d-flex gap-4 ${activeTab === 'Uncliamed' ? 'active' : ''} text-dark`}
          onClick={() => onTabClick('Uncliamed')}
          style={{
            ...tabStyle,
            ...(activeTab === 'Uncliamed' ? activeTabStyle : inactiveTabStyle),
          }}
        >
          Unclaimed
        </button>
      </div>

      <hr />
    </div>
  )
}

export {ReportsTabs}
